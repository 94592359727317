import styled from 'styled-components'

export const Svg = styled.svg`
  ${
    props => props.color && props.theme.color[props.color]
    ? `fill: ${props.theme.color[props.color]}` : ''
  }
  ${
    props => props.color && props.theme.color[props.stroke]
    ? `stroke: ${props.theme.color[props.stroke]}` : ''
  }
  transition: all 0.3s ease-in-out;
  path {
    ${
      props => props.stroke && props.theme.color[props.stroke]
      ? `stroke: ${props.theme.color[props.stroke]}` : ''
    }
    ${
      props => props.fill ? `fill: ${props.fill}` : ''
    }
  }
`
