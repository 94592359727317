import React, { createContext, useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import OrdersService from 'services/OrdersService'
import useAuth from './AuthContext'

const OrdersContext = createContext('config')

export const OrdersContextProvider = ({ children }) => {
  const [successMessage, setSuccessMessage] = useState(null)
  const [orders, setOrders] = useState([])
  const [orderHash, setOrderHash] = useState(null)
  const [, setLoading] = useState(false)
  const [, setError] = useState(false)
  const { auth } = useAuth()

  const fetchOrders = () => {
    if (!auth) return
    setLoading(true)
    OrdersService.getOrders()
      .then(data => {
        if (!Array.isArray(data.data)) return
        ReactDOM.unstable_batchedUpdates(() => {
          setLoading(false)
          setError(false)
          setOrders(data.data)
        })
      })
      .catch(() => {
        setLoading(true)
        setError(true)
      })
  }

  useEffect(() => {
    fetchOrders()
    // eslint-disable-next-line
  }, [auth])

  useEffect(() => {
    if (!orderHash) return
    OrdersService.getOrderByHash(orderHash).then(data => {
      if (data.data) {
        setOrders([data.data])
      }
    })
  }, [orderHash])

  return (
    <OrdersContext.Provider
      value={{
        fetchOrders: fetchOrders,
        setSuccessMessage,
        setOrderHash,
        successMessage,
        orders: orders,
      }}
    >
      {children}
    </OrdersContext.Provider>
  )
}

const useOrders = () => useContext(OrdersContext)

export default useOrders
