import React from 'react'
import Button from 'components/Button'
import { Send } from 'components/Icons'
import { CustomInput, InputWrapper } from 'components/Input/Input.style'

const WithButton = props => {
  return (
    <InputWrapper className="send-input">
      <CustomInput {...props} />
      <Button color="blue">
        <Send color="white" />
      </Button>
    </InputWrapper>
  )
}

export default WithButton
