import React from 'react'


const Percent2 = ({ color, size }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0C3.6 0 0 3.6 0 8ZM8.8 7.2H12V8.8H8.8H7.2H4V7.2H7.2H8.8Z" fill="#D9D9D9"/>
    </svg>

  )
}


export default Percent2
