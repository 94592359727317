import React from 'react'
import PropTypes from 'prop-types'
import { Svg } from '../Icons.style'

const EmptyStar = ({ color, width, height }) => {
  return (
    <Svg color={color} xmlns='http://www.w3.org/2000/svg' width={width || '15.747'} height={height || '15.022'} viewBox='0 0 15.747 15.022'>
      <path id='Star' d='M9.139,14.423,5.4,16.657a.486.486,0,0,1-.722-.525l.969-4.248a.486.486,0,0,0-.154-.474L2.21,8.544a.486.486,0,0,1,.276-.849L6.825,7.3a.486.486,0,0,0,.4-.293L8.941,3a.486.486,0,0,1,.893,0L11.548,7.01a.486.486,0,0,0,.4.293l4.339.391a.486.486,0,0,1,.276.849l-3.28,2.867a.486.486,0,0,0-.154.474l.969,4.248a.486.486,0,0,1-.722.525L9.637,14.423A.486.486,0,0,0,9.139,14.423Z' transform='translate(-1.485 -2.207)' fill='#fff' stroke='#c9c9c9' strokeWidth='1' fillRule='evenodd' />
    </Svg>
  )
}

EmptyStar.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

export default EmptyStar
