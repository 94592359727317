import { Check } from 'components/Icons'
import {
  Checkbox,
  CheckboxInput,
  LabelWithInput,
} from 'components/Input/Input.style'
import PropTypes from 'prop-types'
import React from 'react'

const CustomCheckbox = props => {
  const { type: _, ...rest } = props
  return (
    <LabelWithInput>
      <CheckboxInput {...rest} type="checkbox" />
      <Checkbox>
        <Check />
      </Checkbox>
      {props.title}
    </LabelWithInput>
  )
}

CustomCheckbox.propTypes = {
  title: PropTypes.string,
}

export default CustomCheckbox
