import { BrowserView } from 'components/DeviceDetect'
import { WoundedArrow } from 'components/Icons'
import getProductUrl from 'helpers/getProductUrl'
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import useProduct from 'store/SingleProductProvider'
import { ProductHead } from './Product.style'

const LandingProductHead = () => {
  const {
    product,
    product: { landing },
  } = useProduct()
  return (
    <Fragment>
      <BrowserView>
        <img
          src="/img/landing-circle-big.svg"
          alt=""
          className="landing-circle-big"
        />
        <img
          src="/img/landing-circle-small.svg"
          className="landing-circle-small"
          alt=""
        />
        <img
          src="/img/landing-circle-orange.svg"
          className="landing-circle-orange"
          alt=""
        />
      </BrowserView>
      <ProductHead>
        <BrowserView>
          <Link to={getProductUrl(product?.slug)}>
            <h3>{product?.name}</h3>
          </Link>
          <h3 style={{ color: 'green' }}>
            {landing.isCgd ? (
              <>შეუკვეთე ახლავე {product?.price}₾</>
            ) : (
              <>
                შეუკვეთე ახლავე {product?.installment_price}₾<span>/თვეში</span>
              </>
            )}
            <WoundedArrow color="orange" width="70.32" height="53.43" />
          </h3>
        </BrowserView>
      </ProductHead>
    </Fragment>
  )
}

export default LandingProductHead
