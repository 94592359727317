export const onProductViewConversion = (product, options = {}) => {
  /* eslint-disable-next-line */
  fbq('track', 'ViewContent', {
    content_name: product.name + ' Landing',
    content_category: product.breadcrumb?.[product.breadcrumb.length - 1].name,
    content_ids: [product.sku],
    content_type: 'product',
    value: product.price,
    currency: 'GEL',
  })
}

export const onLandingSentConversion = (product, options) => {
  /* eslint-disable-next-line */
  fbq('track', 'Lead', { eventID: options.order_id })

  if (options.cgd) {
    /* eslint-disable-next-line */
    fbq('track', 'fastLandingFormSent', {}, { eventID: options.order_id })
  } else {
    /* eslint-disable-next-line */
    fbq('track', 'Form1Sent', {}, { eventID: options.order_id })
  }

  /* eslint-disable-next-line */
  fbq('track', 'ViewContent', productToFb(product))

  /* eslint-disable-next-line */
  if (typeof gtag !== 'undefined') {
    /* eslint-disable-next-line */
    gtag('event', 'purchase', {
      event_label: `${product.name}|${product.sku}|${
        options.cgd ? 'cash' : 'ganvadeba'
      }`,
      value: product.price,
    })
    /* eslint-disable-next-line */
    gtag('event', 'conversion', {
      send_to: 'AW-378219668/sSEACIfu35oCEJTZrLQB',
      value: product.price,
      currency: 'GEL',
    })
  }
}

export const onProductAddToCartEvent = (product, options) => {
  /* eslint-disable-next-line */
  fbq('track', 'AddToCart', productToFb(product))

  /* es`lint-disable-next-line */
  if (typeof gtag !== 'undefined') {
    /* eslint-disable-next-line */
    gtag('event', 'add_to_cart', {
      event_label: `${product.name}|${product.sku}|${options.payMethod}`,
      value: product.price,
    })
  }
}

export const onCartCheckoutConversion = (products, options) => {
  console.log(options)
  /* eslint-disable-next-line */
  fbq('track', 'InitiateCheckout', {
    content_ids: products.map(pr => pr.sku),
    currency: 'GEL',
    num_items: products.length,
    value: options.total,
  })

  /* eslint-disable-next-line */
  fbq('track', 'Lead', { eventID: options.order_id })

  if (options.payMethod === 'installment') {
    /* eslint-disable-next-line */
    fbq('track', 'Form1Sent', { eventID: options.order_id })
  } else if (options.payMethod === 'cash') {
    /* eslint-disable-next-line */
    fbq('track', 'fastLandingFormSent', { eventID: options.order_id })
  } else if (options.payMethod === 'card' || options.payMethod === 'bank') {
    /* eslint-disable-next-line */
    fbq('track', 'Add Payment Info', { eventID: options.order_id })
  }

  /* eslint-disable-next-line */
  if (typeof gtag !== 'undefined') {
    /* eslint-disable-next-line */
    gtag('event', 'purchase', {
      event_label: products
        .map(p => `${p.name}|${p.sku}|${options.payMethod}`)
        .join(','),
      value: options.total,
    })
    /* eslint-disable-next-line */
    gtag('event', 'conversion', {
      send_to: 'AW-378219668/sSEACIfu35oCEJTZrLQB',
      value: options.total,
      currency: 'GEL',
    })
  }
}

export const pageViewEvent = (product = null) => {
  if (typeof gtag !== 'undefined') {
    const data = {
      send_to: 'AW-378219668',
    }
    if (product) {
      data.value = product.price
      data.items = [{ id: product.sku, google_business_vertical: 'retail' }]
      /* eslint-disable-next-line */
      gtag('event', 'view_item', data)
    } else {
    }

    /* eslint-disable-next-line */
    gtag('event', 'page_view', data)
  }
}

const productToFb = product => {
  return {
    content_name: product.name,
    content_category: product.breadcrumb?.[product.breadcrumb.length - 1].name,
    content_ids: [product.sku],
    content_type: 'product',
    value: product.price,
    currency: 'GEL',
  }
}
