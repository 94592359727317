import React from 'react'
import { StarsWrapper } from './Stars.style'

const generateStar = (amount, color, width, height) => {
  const stars = []

  for (let i = 0; i < amount; i++) {
    stars.push(
      <img width="12" height="12" key={i} src="/svg/star-orange.svg" alt="" />
    )
  }

  return stars
}

const Stars = props => {
  return (
    <StarsWrapper>
      {generateStar(props.amount, props.color, props.width, props.height)}
    </StarsWrapper>
  )
}

export default Stars
