import React from 'react'
import PropTypes from 'prop-types'
import { Svg } from '../Icons.style'

const Warning = ({ color, width, height, ...rest }) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '20.25'}
      height={height || '20.25'}
      viewBox="0 0 20.25 20.25"
      color={color}
      {...rest}
    >
      <g>
        <g>
          <path
            d="M10.125,0A10.125,10.125,0,1,0,20.25,10.125,10.119,10.119,0,0,0,10.125,0Zm0,18.668a8.543,8.543,0,1,1,8.543-8.543A8.538,8.538,0,0,1,10.125,18.668Z"
            fill="red"
          />
        </g>
      </g>
      <g transform="translate(9.334 5.097)">
        <g>
          <path
            d="M236.791,128.877a.791.791,0,0,0-.791.791v5.094a.791.791,0,1,0,1.582,0v-5.094A.791.791,0,0,0,236.791,128.877Z"
            transform="translate(-236 -128.877)"
            fill="red"
          />
        </g>
      </g>
      <g transform="translate(9.057 12.742)">
        <g>
          <circle cx="1.068" cy="1.068" r="1.068" fill="red" />
        </g>
      </g>
    </Svg>
  )
}

Warning.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default Warning
