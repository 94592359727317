import Button from 'components/Button'
import ErrorMessage from 'components/ErrorMessage'
import { Check } from 'components/Icons'
import * as S from 'components/Widgets/LoginModal/LoginModal.style'
import React, { useState } from 'react'
import UserService from 'services/UserService'
import useAuth from 'store/AuthContext'

const RegisterForm = props => {
  const { onLogin } = props
  const { userAuthenticated } = useAuth()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(false)
  const [resend, setResend] = useState(false)
  const [confirmation, setConfirmation] = useState(false)
  const [phone, setPhone] = useState('')

  const onRegister = e => {
    e.preventDefault()
    const data = new FormData(e.target)
    setLoading(true)
    UserService.register(data)
      .then(res => {
        if (!res.success) return
        userAuthenticated()
      })
      .catch(err => {
        setLoading(false)
        const res = err.response.data
        if (err.response?.status === 409) {
          setResend(true)
        }
        res.errors && setErrors(res.errors)
      })
  }

  const sendConfirmationCode = () => {
    if (phone.length !== 9) {
      return setErrors({
        phone: 'გთხოვთ შეიყვანოთ 9 ნიშნა ფორმატით: 5xxxxxxxx',
      })
    }
    UserService.sendConfirmationCode(phone)
      .then(res => {
        setConfirmation(true)
        setErrors({})
        setResend(false)
      })
      .catch(err => {
        return setErrors({
          confirmation_code_error: 'შეცდომა კოდის გაგზავნისას',
        })
      })
  }

  return (
    <S.LoginModalForm className="register" onSubmit={onRegister}>
      <h3>გაიარეთ რეგისტრაცია და მიიღეთ თქვენზე მორგებული შეთავაზებები</h3>
      <S.LabelWithInput>
        <p>სახელი/გვარი *</p>
        <S.LoginInput
          type="text"
          name="name"
          placeholder="სახელი და გვარი"
          key="registerName"
        />
        <ErrorMessage show={errors.name} message={errors.name} />
      </S.LabelWithInput>
      <S.LabelWithInput>
        <p>ტელეფონი *</p>
        <S.LoginInput
          type="text"
          name="phone"
          defaultValue={phone}
          onChange={e => setPhone(e.target.value)}
          placeholder="5XX XXX XXX"
        />
        <ErrorMessage show={errors.phone} message={errors.phone} />
        <ErrorMessage
          show={errors.confirmation_code_error}
          message={errors.confirmation_code_error}
        />
        <ErrorMessage
          show={resend}
          message={
            <a href="javascript:void(0)" onClick={() => sendConfirmationCode()}>
              ავტორიზაციის კოდის მოთხოვნა
            </a>
          }
        />
      </S.LabelWithInput>
      {confirmation && (
        <S.LabelWithInput>
          <p>ავტორიზაციის კოდი *</p>
          <S.LoginInput type="text" name="auth_code" placeholder="XXXXX" />
          <ErrorMessage show={errors.auth_code} message={errors.auth_code} />
        </S.LabelWithInput>
      )}
      <Button type="submit" color="orange">
        რეგისტრაცია
      </Button>
      <Button
        color="lightBlue"
        onClick={() => onLogin && onLogin()}
        disabled={loading}
      >
        დაბრუნდი ავტორიზაციაზე
      </Button>
      <S.RegisterInfo>
        <p>რეგისტრაციის შემდეგ თქვენ შეძლებთ: </p>
        <ul>
          <li>
            <Check width="13" height="9" color="blue1" /> მიუთითოთ მისამართი
          </li>
          <li>
            <Check width="13" height="9" color="blue1" /> დააჩქაროთ ყიდვის
            პროცესი
          </li>
          <li>
            <Check width="13" height="9" color="blue1" /> თვალი ადევნოთ შეკვეთის
            მიმდინარეობას
          </li>
        </ul>
      </S.RegisterInfo>
    </S.LoginModalForm>
  )
}

export default RegisterForm
