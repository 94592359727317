import styled from 'styled-components'

export const CustomButton = styled.button`
  border: none;
  outline: none;
  background: none;
  box-shadow: unset;
  cursor: pointer;
  text-align: center;
  font-size: ${props => props.theme.font.size.sm};
  &:disabled {
    cursor: not-allowed;
  }
  @media screen and (min-width: 1100px) {

  }
`
export const OldOrangeButton = styled(CustomButton)`
  padding: 14px 20px;
  border-radius: 24px;
  background-color: ${props => props.theme.color.orange};
  color: ${props => props.theme.color.white};
  @media screen and (min-width: 1100px) {

  }
`
export const OrangeButton = styled(CustomButton)`
  
  padding: 16px 20px;
  //height: 65px;
  border-radius: 10px;
  background-image: linear-gradient(to right top, #ff4b2b, #ff453e, #ff424e, #ff405d, #ff416c);
  //background-image: linear-gradient(to right, #ff4b2b, #ff453e, #ff424e, #ff405d, #ff416c);
  color: ${props => props.theme.color.white};
  p{
    font-size: 18px;
    font-family: ${props => props.theme.font.family.medium};
  }
  svg{
    color: white;
  }
`

export const SmallOrangeButton = styled(CustomButton)`
  padding: 8px 10px;
  border-radius: 24px;
  background-color: ${props => props.theme.color.orange};
  color: ${props => props.theme.color.white};
  @media screen and (min-width: 1100px) {

  }
`

export const SearchButton = styled(CustomButton)`
  &:hover{
      background-color: #ff2337;
  }
  padding: 11px 16px;
  border-radius: unset;
  background-color: #ff2337;
  color: ${props => props.theme.color.white};
  svg{
    position: absolute;
    top:13px;
    left: 15px;
  }
  @media screen and (min-width: 1100px) {

  }
`

export const LightBlueButton = styled(CustomButton)`
  padding: 14px 20px;
  border-radius: 24px;
  background-color: ${props => props.theme.color.lightBlue};
  color: ${props => props.theme.color.blue1};
  svg {
    margin-left: 10px;
  }
  @media screen and (min-width: 1100px) {

  }
`

export const BlueButton = styled(CustomButton)`
  padding: 11px 16px;
  border-radius: 20px;
  background-color: ${props => props.theme.color.blue1};
  color: ${props => props.theme.color.white};
  @media screen and (min-width: 1100px) {

  }
`
export const TransparentButton = styled(CustomButton)`
  padding: 11px 16px;
  border-radius: 20px;
  background-color: ${props => props.theme.color.white};
  color: ${props => props.theme.color.white};
  @media screen and (min-width: 1100px) {

  }
`

export const WhiteButton = styled(CustomButton)`
  padding: 11px 20px;
  border-radius: 20px;
  background-color: ${props => props.theme.color.white};
  color: ${props => props.theme.color.black2};
  font-size: ${props => props.theme.font.size.sm};
  border: solid 1px #e3e8ed;
  svg {
    margin-left: 10px;
  }
  @media screen and (min-width: 1100px) {

  }
`

export const GreyButton = styled(CustomButton)`
  padding: 11px 18px;
  border-radius: 4px;
  background-color: ${props => props.theme.color.white};
  color: ${props => props.theme.color.black4};
  font-size: ${props => props.theme.font.size.sm};
  border: solid 1px #DFE3E9;

  &.active {
    color: ${props => props.theme.color.orange};
    background-color: rgba(255,167,1,0.08);
    border-color: #FFA701;
    font-family: ${props => props.theme.font.family.medium};
  }
  &:disabled {
    border: 2px dashed grey;
  }
  @media screen and (min-width: 1100px) {
  
    
  }
`

export const HexColorButton =styled(CustomButton)`
  border-radius:100%;
  background-color:white;
  height: 44px;
  
  &.active {
    border: 2px solid #FFA701;
  }
  &:disabled {
    border: 2px dashed grey;
  }
`

export const HexColorButtonInner = styled.div`
  width: 40px;
  height: 40px;
  border-radius:100%;
  background-color: ${props => props.backgroundColor};
  pointer-events:none;

 

`
export const RoundedGreyButton = styled(CustomButton)`
  padding: 15px 25px;
  border-radius: 4px;
  background-color: #DFE3E9;
  border-radius: 24px;
  color: #141414;
  font-size: ${props => props.theme.font.size.sm};
  @media screen and (min-width: 1100px) {

  }
`

export const FacebookButton = styled(CustomButton)`
  background-color: #4a73c7;
  border: none;
  border-radius: 30px;
  text-align: center;
  color: white;
  padding: 9px;
  width: 100%;
  font-size: 16px;
  max-height: 45px;
`
export const GoogleButton = styled(FacebookButton)`
  background-color: #4285f4;
`
