import styled from 'styled-components'

export const MessageDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 14px;
  ${props => {
    if(props.type === 'warning'){
      return `background-color: rgba(255,165,2,0.15);`
    } else if(props.type === 'success'){
      return `background-color: #7cb64a;`
    } else if(props.type === 'error'){
      return `background-color: #ff656596;`
    }
  }}
  border-radius: 10px;
  margin-top: 8px;
  p {
    color: ${props => props.type === 'success' ? "white" : 'black'} !important;
    font-size: 14px;
    a {
      color: #FFA502;
      margin-left: 5px;
    }
  }

  @media screen and (min-width: 1100px) {}
`