import styled from 'styled-components'

export const SpecOfferContainer = styled.div`
  width: 100%;
  position: relative;
  background-color: #FFF2F2;
  /* margin-top: 16px; */
  padding-top: 8px;
  padding-bottom: 78px;
  border-radius: 25px;
  
  .spec-offer-wrapper {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    a {
      text-decoration: none;
      img {
        width: 110px;
      }
    }

    h3 {
      display: flex;
      align-items: center;
      margin-top: 10px;
      font-weight: 'bold';
      font-size: 14px;
      color: #141414;
      font-family: ${props => props.theme.font.family.bold};
    }
    .product-name {
      font-size: 14px;
      padding: 0 12px;
      margin:0 ;
    }
    .spec-offer-text {
      font-family: 'FiraGo-Medium';
    }
    p {
      margin-top: 13px;
      font-size: 14px;
      color: #141414;
      line-height: 24px;
    }
    @media screen and (min-width: 760px) {
      h3 {
        font-size: 22px;
        font-weight: normal;
      }
    }
  }
  @media screen and (min-width: 760px) {
    text-align: unset;
    h3 {
      font-size: 24px;
      margin-top: 0;
      img {
        margin-right: 30px;
      }
    }
  }

`

export const SpecOfferImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;

  img {
    width: 100%;
  }
  @media screen and (min-width: 1100px) {
    top: -40px
  }

`

export const SpecOfferTime = styled.div`
  position: absolute;
  left: 50%;
  width: 95%;
  bottom: -37px;
  transform: translateX(-50%);
  @media screen and (min-width: 1100px) {
    bottom: -40px;
    width: auto;
  }

`
