import React from 'react'
import PropTypes from 'prop-types'
import { Svg } from '../Icons.style'

const Send = ({ color, width, height }) => {
  return (
    <Svg color={color} xmlns='http://www.w3.org/2000/svg' width={width || '16'} height={height || '16'} viewBox='0 0 16 16'>
      <path id='send' d='M16 0L0 9l5.113 1.894L13 3.5l-6 8.093h-.006V16l2.868-3.345L13.5 14z' />
    </Svg>
  )
}

Send.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

export default Send
