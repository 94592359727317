import { Check } from 'components/Icons'
import { Container } from 'layouts/Layout.style'
import React from 'react'
import { OfferContainer, OfferText, WeOffer } from './Offer.style'

const Offer = props => {
  return (
    <OfferContainer className="">
      <Container className="container">
        <OfferText>
          <p>
            Citrus.ge - ინტერნეტ მაღაზია, რომლის მთავარი მიზანი ქართველი
            მომხმარებლის სურვილებზე მაქსიმალურად მორგებული ინტერნეტ სივრცის
            შექმნა და ონლაინ შოპინგის მარტივი პროცესის ორგანიზებაა.
          </p>
          <h4>
            <img alt="" src="/img/medal.png" />
            <img alt="" className="line" src="/img/offer-emph.svg" />
            “500 000 ზე მეტი კმაყოფილი კლიენტი”
          </h4>
        </OfferText>
        <WeOffer>
          <h3>ჩვენ გთავაზობთ:</h3>
          <ul>
            <li>
              <Check color="blue1" width="12" height="9.39" />
              მარტივ მომსახურებას
            </li>
            <li>
              <Check color="blue1" width="12" height="9.39" />
              მაღალხარისხიან პროდუქციას
            </li>
            <li>
              <Check color="blue1" width="12" height="9.39" />
              სწრაფ და მოხერხებულ მიტანის სერვისს
            </li>
            <li>
              <Check color="blue1" width="12" height="9.39" />
              მომხმარებელზე მორგებულ მომსახურებას
            </li>
          </ul>
        </WeOffer>
      </Container>
    </OfferContainer>
  )
}

export default Offer
