import React from 'react'
import Loader from 'react-loader-spinner'
import { MessageDiv } from './UI.style'

const Message = ({ message, loading, type, show }) => {
  if (show === false && !loading) return null

  if (loading) {
    return (
      <div className="flex items-center justify-center w-100">
        <Loader type="ThreeDots" />
      </div>
    )
  }

  return (
    <MessageDiv type={type}>
      <p>{message}</p>
    </MessageDiv>
  )
}

export default Message
