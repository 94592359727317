import styled from 'styled-components'

export const CountDownWrapper = styled.div`
  display: flex;
  color: #383838;
  font-size: ${props => props.theme.font.size.lg};
  span.dots {
    display: inline-block;
    padding-top: 16px;
    font-size: 16px;
  }
  @media screen and (min-width: 1100px) {

  }
`

export const CountDownTime = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #383838;
  padding: 0px 6px;
  p {
    width: 50px;
    height: 50px;
    background-color: #1E90FF;
    color: white;
    font-size: 31px;
    text-align: center;
    line-height: 50px;
    border-radius: 100%;
    font-family: ${props => props.theme.font.family.medium};

  }
  span {
    font-size: ${props => props.theme.font.size.sm};
    font-weight: 500;
    font-family: ${props => props.theme.font.family.medium};
    margin-top: 5px;
    letter-spacing: -0.28px;
    color: #5C6772;
  }
  @media screen and (min-width: 1100px) {

  }
`

export const SpecOfferCountDown = styled.div`
  background: white;
  border-radius: 16px;
  box-shadow: 0px 3px 13px rgba(181,199,206,0.38);
  display: flex;
  padding: 18px 36px 15px 28px;
  @media screen and (min-width: 1100px) {

  }
`

export const SpecOfferCountDownTime = styled.div`
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 30px;
    font-family: ${props => props.theme.font.family.bold};
    color: #141414;
  }

  span {
    font-size: 12px;
  }

  &:not(:first-child) {
    margin-left: 50px;
    position: relative
    &::before {
      content: ':';
      font-size: 54px;
      font-family: ${props => props.theme.font.family.bold};
      color: #141414;
      position: absolute;
      left: -34px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &:last-child {
    p,span {
      color: #FF0000;
    }
  }
  @media screen and (min-width: 1100px) {
    p {
      font-size: 54px;
    }
  }

`

export const RoundedCountDown = styled.div`
  border: 2px solid #FFA502;
  color: #141414;
  font-size: 12px;
  padding: 11px 17px;
  border-radius: 20px;
  text-align: center;
  i {
    display: inline-block;
    font-style: normal;
    margin-left: 10px;
  }
  @media screen and (min-width: 1100px) {
    display: none;
    &.desktop-countdown {
      display: block;
      font-size: 14px;
    }
  }
`
