import styled from 'styled-components'

export const FooterContainer = styled.div`
  display: grid;
  @media screen and (min-width: 1100px) {
    grid-template-columns: 4.5fr 4.5fr;
  }
`

export const FooterInfoContainer = styled.div`
  width: 100%;
  
  @media screen and (min-width: 1100px) {
    padding: 0px;

  }

`

export const FooterInfoContent = styled.div`
  width: 100%;
  padding: 15px;
  background-color: #ff5a00;

  a {
    margin-bottom: 34px;
    img {
      width: 129px;
    }
  }

  a.call {
    display: grid;
    grid-template-rows: 25px auto;
    grid-template-columns: 41px auto;
    grid-column-gap: 15px;
    margin-top: 34px;
    grid-template-areas: 'phone text'
                        'phone number';


    i {
      width: 41px;
      height: 41px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      grid-area: phone;
    }

    span {
      font-size: 14px;
      color: white;
      grid-area: text;
      &.phone-number {
        font-size: 16px;
        grid-area: number;
      }
    }

  }

  p {
    font-family: ${props => props.theme.font.family.bold};
    color: white;
    font-size: 16px;
    margin-top: 35px;
  }

  address {
    color: white;
    font-size: 16px;
    margin-top: 15px;
    font-style: normal;
  }
  @media screen and (min-width: 1100px) {
    padding-top: 42px;
    padding-bottom: 55px;
  }

`

export const FooterSocialContent = styled.div`
  padding: 15px;
  padding-top: 28px;
  padding-bottom: 23px;
  background-color: white;
  a {
    img {
      width: 26px;
    }
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
  @media screen and (min-width: 1100px) {
    padding-left: 0px;
    padding-right: 0px;
  }


`

export const FooterGooGleMapContent = styled.div`
  height: 250px;
  @media screen and (min-width: 1100px) {
    height: auto;
    display: none;
    &.desktop-map {
      display: block;
    }
  }
`
