import styled from 'styled-components'

export const ProductContainer = styled.div`
  margin-top: 45px;
  width: 100%;
  display: grid;
  grid-row-gap: 10px;

  @media screen and (min-width: 1100px) {
    position: relative;

    .landing-circle-big {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 100px;
      z-index: -1;
    }
    .landing-circle-orange {
      position: absolute;
      top: 95px;
      left: -50px;
    }
    .landing-circle-small {
      position: absolute;
      right: -125px;
      top: -85px;
    }
  }


`

export const ProductHead = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  padding: 0px 15px;
  h3 {
    font-size: 18px;
    display: flex;
    font-family: ${props => props.theme.font.family.bold};
    color: ${props => props.theme.color.black1};
    margin: 0px;
    padding-right: 107px;
    span {
      font-size: 14px;
      font-family: ${props => props.theme.font.family.regular};
    }
    svg {
        transform: rotate(40deg);
        position: absolute;
        right: 40px;
    }
  }
  @media screen and (min-width: 1100px) {
    h3 {
      font-size: 30px;
    }
  }

`

export const ProductContent = styled.div`
  display: grid;
  @media screen and (min-width: 1100px) {
    grid-template-columns: 4.5fr 4.5fr;
    grid-column-gap: 30px;
    
  }

`

export const ProductImage = styled.div`
  width: 100%;
  padding: 0px 15px;
  border-radius: 15px;
  display: grid;
  grid-template-rows: auto auto;
  justify-items: center;
  align-items: center;
  box-sizing: border-box;
  background-color: white;

  img.product-main-pic {
    width: 100%;
    box-shadow: 0px 3px 35px rgba(141,162,177, 0.11);
    border: 1px solid #F2F4F8;
    border-radius: 20px;
    margin-top: 20px;
  }

  @media screen and (min-width: 1100px) {
    padding: 46px 15px;
    padding-top: 31px;
    box-shadow: 0px 3px 35px rgba(141,162,177, 0.11);
    border: 1px solid #F2F4F8;

    img.product-main-pic {
      max-width: 540px;
      order: 1;
      border: none;
      box-shadow: none;
      margin: 0px;
      margin-bottom: 16px;
    }
  }

`

export const ProductInfo = styled.div`
  border: 1px solid rgb(217, 217, 217);
  background-color: #edeff3;
  margin-top: 20px;
  @media screen and (min-width: 1100px) {
    background-color: none;
    border-radius: 15px;
    margin-top: 0px;
  }

`

export const ProductConditions = styled.div`
  width: 100%;
  display: grid;
  grid-row-gap: 13px;
  @media screen and (min-width: 1100px) {
    width: auto;
    grid-template-columns: auto auto;
    grid-column-gap: 63px;
    gir-row-gap: 0px;
    order: 2;
  }
`
export const Condition = styled.div`
  display: grid;
  grid-template-columns: 36px 1fr;
  grid-template-rows: 17px auto;
  grid-row-gap: 2px;
  grid-column-gap: 14px;
  align-content: center;
  grid-template-areas: "image title"
                      "image description";
  img {
    grid-area: image;
    max-width: 36px;
  }

  h4 {
    grid-area: title;
    font-size: 14px;
    margin: 0;
    font-family: ${props => props.theme.font.family.bold};
    color: ${props => props.theme.color.black1};
  }

  p {
    grid-area: description;
    font-size: 12px;
    color: ${props => props.theme.color.black1};
  }
  @media screen and (min-width: 1100px) {
    grid-template-columns: 68px 1fr;
    grid-row-gap: 8px;

    img {
      max-width: 68px;
    }
    p {
      max-width: 135px;
    }
  }


`

export const InfoContent = styled.div`
  padding-bottom: 29px;
  padding: 15px;
  h3 {
    color: #FF0000;
    font-size: 36px;
    font-family: ${props => props.theme.font.family.bold};
    font-family: Firago-Regular, arial;
    margin: 0px;
    display: flex;
    align-items: flex-end;
    position: relative;

    span.old-price {
      font-size: 28px;
      color: #5A6679;
      text-decoration: line-through;
      margin-left: 24px;
    }
    svg {
      position: absolute;
      top: -14px;
      right: 0px;
      width: 50px;
    }
  }

  p {
    font-size: 24px;
    color: ${props => props.theme.color.black1};
    margin-top: 10px;
  }
  @media screen and (min-width: 1100px) {
    padding: 15px;
    background-color: #edeff3;

    h3 {
      font-size: 48px;
      span {
        font-size: 36px;
      }

      svg {
        width: auto;
        right: unset;
        position: relative;
        top: 30px;
        margin-left: 55px;
      }
    }
    p {
      font-size: 20px;
    }
  }

`

export const ProductForm = styled.form`
  width: 100%;
  display: grid;
  grid-row-gap: 25px;
  margin-top: 20px;

  button {
    background-color: #FF0000;
    color: white;
    width: 100%;
  }
  @media screen and (min-width: 1100px) {
    grid-template-columns: 4.5fr 4.5fr;
    grid-column-gap: 21px;
    grid-template-areas: "name-label phone-label"
                      "button button";


    button {
      grid-area: button;

    }
  }


`

export const LabelWithInput = styled.label`
  display: grid;
  grid-row-gap: 16px;

  

  span {
    font-size: 16px;
    font-family: ${props => props.theme.font.family.medium};
    color: ${props => props.theme.color.black1};
  }

  input {
    border-radius: 24px;
    border: 2px solid #CACFD8
    padding: 14px 24px;
    color: #5A6679;
    background-color: white;
  }
  @media screen and (min-width: 1100px) {
    &.name-label {
      grid-area: name-label;
    }
    &.phone-label {
      grid-area: phone-label;
    }
  }


`

export const WaringArea = styled.div`
  padding: 15px;
  @media screen and (min-width: 1100px) {
  }
`

export const Hint = styled.div`
  padding: 8px 25px;
  padding-bottom: 46px;
  p {
    line-height: 24px;
    color: #5A6679;
  }
  &::before {
    content: '';

  }
 @media screen and (min-width: 1100px) {
   
 }
`
