import React from 'react'
import PropTypes from 'prop-types'
import { Svg } from '../Icons.style'

const Phone = ({ color, width, height }) => {
  return (
    <Svg color={color} xmlns='http://www.w3.org/2000/svg' width={width || '16'} height={height || '16'} viewBox='0 0 16 16'>
      <path id='Phone' d='M3.221 1.053S1 1.77 1 3.656s1.523 5.149 4.858 8.485S12.456 17 14.341 17s2.615-2.365 2.615-2.365a.721.721 0 0 0-.278-.843l-3.3-2.068a1.03 1.03 0 0 0-.986-.007l-1.278.775a1.139 1.139 0 0 1-1.194-.065A21.7 21.7 0 0 1 7.6 10.4a20.593 20.593 0 0 1-2.021-2.258 1.125 1.125 0 0 1-.071-1.189l.774-1.278a1.01 1.01 0 0 0-.016-.982l-2.2-3.378a.712.712 0 0 0-.851-.261z' transform='translate(-1 -1)' />
    </Svg>
  )
}

Phone.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

export default Phone
