import React from 'react'
import PropTypes from 'prop-types'
import { Svg } from '../Icons.style'

const ArrowLeft = ({ color, width, height }) => {
  return (
    <Svg
      color={color} xmlns='http://www.w3.org/2000/svg'
      width={width || '5.499'} height={height || '9.43'}
      viewBox='0 0 5.499 9.43'
      style={{ transform: 'rotate(180deg)' }}
    >
      <g id='angle-arrow-down' transform='rotate(-90 -24.976 34.406)'>
        <path id='Path_2097' d='M.094 59.949l.472-.472a.3.3 0 0 1 .435 0l3.714 3.713 3.714-3.713a.3.3 0 0 1 .435 0l.472.472a.3.3 0 0 1 0 .435l-4.4 4.4a.3.3 0 0 1-.435 0l-4.4-4.4a.3.3 0 0 1 0-.435z' className='cls-1' />
      </g>
    </Svg>
  )
}

ArrowLeft.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

export default ArrowLeft
