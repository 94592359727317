import { BrowserView } from 'components/DeviceDetect'
import getProductUrl from 'helpers/getProductUrl'
import React from 'react'
import { Link } from 'react-router-dom'
import useProduct from 'store/SingleProductProvider'
import { Condition, ProductConditions, ProductImage } from './Product.style'

export const CProductConditions = () => {
  const { product } = useProduct()
  return (
    <ProductConditions>
      <Condition>
        <Link to={getProductUrl(product?.slug)}>
          <img src="/img/delivery.png" alt="" />
        </Link>
        <h4>
          მიწოდება
          {product.delivery_price > 0 ? null : (
            <span className="green">: უფასო</span>
          )}
        </h4>
        <p>მთელი საქართველოს მასშტაბით</p>
      </Condition>
      {product.details?.warranty ? (
        <Condition>
          <img src="/img/guaranty.png" alt="" />
          <h4>გარანტია</h4>
          <p>{product.details?.warranty} თვე</p>
        </Condition>
      ) : null}
    </ProductConditions>
  )
}

const LandingProductLeftSide = () => {
  const { product } = useProduct()

  return (
    <ProductImage>
      <BrowserView>
        <CProductConditions />
      </BrowserView>

      <Link to={getProductUrl(product?.slug)}>
        <img alt="" className="product-main-pic" src={product?.image} />
      </Link>
    </ProductImage>
  )
}

export default LandingProductLeftSide
