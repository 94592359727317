import cookie from 'helpers/cookie';
import url from 'helpers/url';
import React, { createContext, useContext, useEffect } from 'react'
import AffiliateService from 'services/AffiliateService';
import CartService from 'services/CartService';
import api from 'services/api';
import useAuth from 'store/AuthContext'

const AffilateContext = createContext()

export const AffilateContextProvider = ({ children }) => {
  const {user} = useAuth();

  const isAffiliate = () => {
    return Boolean(user?.affiliate?.active);
  }

  const storeVisit = () => {
    const hash = url.get('aff_hash', '');

    if(!hash) return;

    return AffiliateService.storeVisit({
      aff_hash: hash
    }).then(() => {
      console.log('aff visit saved');
    })
  }

  const getAffiliateId = () => {
    const id = url.get('affid', '');
    
    if(String(id).length !== 12)  {
      return null;
    }

    return id;
  }

  const getAffiliateLinkPreview = () => {

    const link = window.location.href;
    const affid = `affid=${user?.affiliate?.id}`;

    return link.includes('?') ?   `${link}&${affid}` : `${link}?${affid}`;
    
  }

  const generateAffiliateLink = (link) => {

    return AffiliateService.generateLink({
      link: link
    })
    
  }

  useEffect(() => {
    storeVisit();
  }, [])
  
  return (
    <AffilateContext.Provider
      value={{
        isAffiliate: isAffiliate,
        getAffiliateId: getAffiliateId,
        storeVisit: storeVisit,
        getAffiliateLinkPreview: getAffiliateLinkPreview,
        generateAffiliateLink: generateAffiliateLink
      }}
    >
      {children}
    </AffilateContext.Provider>
  )
}

const useAffiliate = () => useContext(AffilateContext)

export default useAffiliate
