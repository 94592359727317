import React from 'react'


const Close2 = ({ color, width, height }) => {
  return (
    <svg width={width || '14'} height={height ||'14'} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.66388 7.00042L13.6554 2.00915C14.1149 1.54969 14.1149 0.804775 13.6554 0.345388C13.1959 -0.114065 12.451 -0.114065 11.9916 0.345388L6.99998 5.33673L2.0084 0.345322C1.54892 -0.114131 0.804032 -0.114131 0.344557 0.345322C-0.114852 0.804775 -0.114852 1.54969 0.344557 2.00908L5.33614 7.00036L0.344557 11.9917C-0.114852 12.4512 -0.114852 13.1961 0.344557 13.6555C0.804032 14.1149 1.54892 14.1149 2.0084 13.6555L6.99998 8.66412L11.9916 13.6555C12.451 14.1149 13.1959 14.1149 13.6554 13.6555C14.1149 13.196 14.1149 12.4512 13.6554 11.9917L8.66388 7.00042Z" fill={color || '#B3B3B3'}/>
    </svg>

  )
}

export default Close2



