import styled from 'styled-components'

export const RangeSlider = styled.div`
  position: relative;
  width: 210px;
  height: 20px;
  @media screen and (min-width: 1100px) {

  }

`

export const RangeInput = styled.input`
  pointer-events: none;
  position: absolute;
  -webkit-appearance: none;
  border: none;
  left: 0;
  top: 0;
  width: 210px;
  outline: none;
  height: 4px;
  margin: 0;
  padding: 0;

  &::-webkit-slider-runnable-track {
    height: 4px;
    border: none;
    border-radius: 3px;
    background: transparent;
  }

  &::-webkit-slider-thumb {
    pointer-events: all;
    position: relative;
    z-index: 1;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border: none;
    border-radius: 100%;
    background-color: ${props => props.theme.color.white};
    border: 4px solid ${props => props.theme.color.orange1};
  }
  @media screen and (min-width: 1100px) {

  }

`

export const RangeTrack = styled.div`
  width: 100%;
  height: 4px;
  left: 0;
  top: 7px;
  position: absolute;
  background-color: ${props => props.theme.color.orange1};
  @media screen and (min-width: 1100px) {

  }
`

export const RangeTrackActive = styled(RangeTrack)`
  background-color: ${props => props.theme.color.greyE5};

  @media screen and (min-width: 1100px) {
  }
`
