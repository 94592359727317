import React from 'react'
import PropTypes from 'prop-types'
import { Svg } from '../Icons.style'

const User = ({ color, width, height }) => {
  return (
    <Svg className='user-empty-icon' color={color} xmlns='http://www.w3.org/2000/svg' width={width || '12.3'} height={height || '14.347'} viewBox='0 0 12.3 14.347'>
      <path id='user' d='M5.5,5.286A3.143,3.143,0,1,0,2.357,2.143,3.143,3.143,0,0,0,5.5,5.286ZM7.7,6.547H7.29a4.274,4.274,0,0,1-3.58,0H3.3A3.3,3.3,0,0,0,0,9.847v1.021a1.179,1.179,0,0,0,1.179,1.179H9.821A1.179,1.179,0,0,0,11,10.869V9.847A3.3,3.3,0,0,0,7.7,6.547Z' transform='translate(0.65 1.65)' fill='none' stroke='#5a6679' strokeWidth='1.3' />
    </Svg>
  )
}

User.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

export default User
