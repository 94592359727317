import styled from 'styled-components'

export const OfferContainer = styled.div`
  width: 100%;
  padding: 39px 15px;
  background-color: #DFE3E9;

  .container {
    display: grid;
    grid-column-gap: 20px;
  }
  @media screen and (min-width: 1100px) {
    padding: 39px 0px
    .container {
      grid-template-columns: 4.5fr 4.5fr;
    }
  }


`

export const OfferText = styled.div`
  padding-bottom: 50px;

  p {
    max-width: 555px;
    font-size: 16px;
    color: #1A2738;
    line-height: 24px;
    margin-bottom: 24px;
  }

  h4 {
    position: relative;
    font-size: 22px;
    color: #1A2738;
    font-family: ${props => props.theme.font.family.bold};
    margin: 0px;
    display: flex;
    align-items: center;

    img {
      width: 38px;
      margin-right: 24.5px;

      &.line {
        position: absolute;
        bottom: -30px;
        left: 50px;
        width: 188px;
      }
    }
  }
  @media screen and (min-width: 1100px) {
    padding-bottom: 30px;
  }

`

export const WeOffer = styled.div`
  h3 {
    font-size: 16px;
    font-family: ${props => props.theme.font.family.medium};
    color: #1A2738;
  }

  ul {
    list-style: none;
    margin: none;
    margin-top: 15px;

    li {
      svg {
        margin-right: 10px;
      }
      font-size: 16px;
      color: #1A2738;

      &:not(:first-child) {
        margin-top: 20px;
      }
    }
  }
  @media screen and (min-width: 1100px) {
    
  }

`
