import ReactDOM from 'react-dom'
import React, { useContext, useState, createContext, useEffect } from 'react'
import ConfigService from 'services/ConfigService'
import useCache from './CacheProvider'

const ConfigContext = createContext('config')

export const ConfigContextProvider = ({ children }) => {
  const { getCache } = useCache()
  const [config, setConfig] = useState(
    getCache('CONFIG', {
      sliders: null,
      banners: [],
      categories: [],
      topCategories: [],
      footer: [],
      settings: {},
      topBar: {},
      translate: {},
      isLoaded: false,
    })
  )
  const [isLoaded, setLoaded] = useState(Boolean(config.isLoaded))

  useEffect(() => {
    getConfigFromServer()
    return
  }, [])

  const getConfigFromServer = () => {
    Promise.all([
      ConfigService.getConfig(),
      ConfigService.getCategories(),
      ConfigService.getTopCategories(),
    ]).then(([conf, cats, topCats]) => {
      ReactDOM.unstable_batchedUpdates(() => {
        setConfig({
          ...conf,
          categories: cats.categories,
          topCategories: topCats.topCategories,
        })
        setLoaded(true)
      })
    })
  }

  const getBanners = (position, count) => {
    if (!Array.isArray(config.banners)) return []

    if (count === 'all') {
      const banner = config.banners.filter(item => item.position === position)
      return banner || []
    }

    return config.banners.find(item => item.position === position)
  }

  return (
    <ConfigContext.Provider
      value={{
        sliders: config.sliders,
        categories: config.categories,
        topCategories: config.topCategories,
        footer: config.footer,
        translate: config.translate,
        settings: config.settings,
        topBar: config.topBar,
        isLoaded: isLoaded,
        getBanners: getBanners,
      }}
    >
      {children}
    </ConfigContext.Provider>
  )
}

const useConfig = () => useContext(ConfigContext)

export default useConfig
