import React from 'react'
import PropTypes from 'prop-types'
import { CustomInput, TextArea } from './Input.style'
import {
  WithSearchButton,
  WithSendButton,
  Checkbox,
  Radio,
  Range,
} from './Components'

const Input = React.forwardRef((props, ref) => {
  switch (props.component) {
    case 'search':
      return <WithSearchButton {...props} ref={ref} autoComplete="off" />
    case 'send':
      return <WithSendButton {...props} autoComplete="off" />
    case 'checkbox':
      return <Checkbox {...props} autoComplete="off" />
    case 'radio':
      return <Radio {...props} autoComplete="off" />
    case 'range':
      return <Range {...props} autoComplete="off" />
    case 'textarea':
      return <TextArea {...props} autoComplete="off" />
    default:
      return <CustomInput {...props} ref={ref} autoComplete="off" />
  }
})

Input.propTypes = {
  component: PropTypes.string,
}

export default Input
