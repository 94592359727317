import styled from 'styled-components'

export const WarningContainer = styled.div`
  @media screen and (min-width: 1100px) {
    display: flex;
    align-items: center;
    grid-template-columns: 21px 1fr auto;
    grid-column-gap: 13px;
    border-radius: 13px;
    padding: 26px 19px;
    align-content: center;
    background-color: rgba(255, 0, 0, 0.08);
  }

`

export const WarningDesc = styled.p`
  @media screen and (min-width: 1100px) {
    font-size: 14px;
    color: ${props => props.theme.color.black2};
  }

`

export const WarningText = styled.p`
  @media screen and (min-width: 1100px) {
    font-size: 14px;
    font-family: ${props => props.theme.font.family.bold};
    color: #FF0000;
  }

`
