import React, { useEffect, useState } from 'react'
import useAffiliate from 'store/AffilateContext'
import './affiliate.scss'
import Input from 'components/Input'
import Button from 'components/Button'
import useAuth from 'store/AuthContext'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const AffiliateToolbar = props => {
  const { isAffiliate, getAffiliateLinkPreview, generateAffiliateLink } =
    useAffiliate()
  const { user } = useAuth()
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const affActive = React.useMemo(() => isAffiliate(), [user])

  const link = getAffiliateLinkPreview()

  const copyLink = async () => {
    setLoading(true)

    generateAffiliateLink(link)
      .then(data => {
        if (!data.link) return alert('შეცდომა სერვერზე')

        navigator.clipboard.writeText(data.link).then(() => {
          alert('ლინკი კოპირებულია')
        })
      })
      .catch(() => {
        return alert('შეცდომა სერვერზე')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const navigateToStats = () => {
    history.push('/account/affiliate-stats')
  }

  useEffect(() => {
    if (!affActive) {
      document
        .querySelector('#content-container')
        .classList.remove('affiliate-active')
      return
    }

    document
      .querySelector('#content-container')
      .classList.add('affiliate-active')
  }, [affActive])

  if (!affActive) return null

  return (
    <div className="aff-container">
      <div className="flex w-full flex-row items-center justify-between gap-40">
        <div className="flex flex-row items-center gap-2 ">
          <div>Affiliate ლინკი:</div>
          <div className="">
            <Input
              readonly="true"
              type="text"
              style={{ width: '300px' }}
              value={link}
              placeholder="ლინკი"
            />
          </div>
          <div>
            <Button color="orange" onClick={copyLink}>
              {loading ? 'იტვირთება' : 'გენერირება'}
            </Button>
          </div>
        </div>
        <div>
          <Button color="lightBlue" onClick={navigateToStats}>
            სტატისტიკა
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AffiliateToolbar
