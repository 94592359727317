import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Autocomplete = styled.div`
  max-width: 100%;
  width: 100%;
  position: absolute;
  background-color: ${props => props.theme.color.white};
  border-radius: 7px;
  box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.16);
  border: 1px solid ${props => props.theme.color.greyE4};
  left: 0px;
  top: 56px;
  z-index: 9999999;
  padding: 25px 25px 40px 25px;
  box-sizing: border-box;

  display: grid;
  grid-template-rows: 1fr auto auto;
  grid-row-gap: 30px;

  @media screen and (min-width: 1100px) {
    width: calc(100% + 200px);
    max-width: unset;
    grid-template-columns: repeat(3, auto);
    grid-row-gap: 0px;
    grid-column-gap: 30px;
  }
`

export const SeeAll = styled.a`
  width: 100%;
  padding: 5px 10px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  position: absolute;
  bottom: 0px;
  display: block;
  background-color: ${props => props.theme.color.lightBlue2};
  cursor: pointer;
`


export const SuggestionsItem = styled(Link)`
  display: flex;
  text-decoration: none;
  margin-bottom: 16px;
  min-height: 80px
  color: ${props => props.theme.color.black1};
  
  img.search-product-image {
    width: 50px;
    margin-right: 20px;
    object-fit: contain;
  }

  .item-info {
    display: flex;
    flex-direction: column;
  }

  h2 {
    font-size: 16px;
    font-weight: 500;
    color: ${props => props.theme.color.black5};
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  p {
    font-size: 14px;
    color: black;
    font-family: Roboto-Bold;
    /* margin-bottom: 9px; */
  }
  @media screen and (min-width: 1100px) {
    img.search-product-image {
      width: 54px;
    }
  }

`

export const Suggestions = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 370px;

  div${SuggestionsItem} {
    margin-bottom: 26px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  @media screen and (min-width: 1100px) {
    overflow: visible;
    height: auto;
    max-height: unset;
  }

`

export const SuggestionsBackground = styled.div`
  position: fixed;
  top: 150px;
  background: transparent;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9999;
`

export const AutocompleteHeading = styled.h3`
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${props => props.theme.color.mediumGrey};
  margin-bottom: 22px;

`

export const KeywordsItem = styled(Link)`
  font-size: 16px;
  color: ${props => props.theme.color.black5};
  text-decoration: none;
  display: block;

`

export const Keywords = styled.div`
  display: flex;
  flex-direction: column;

  a${KeywordsItem} {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0px;
    }
  }


`

export const CategoryItem = styled(KeywordsItem)`
`

export const Categories = styled(Keywords)`

`
