import React from 'react'
import PropTypes from 'prop-types'
import { Svg } from '../Icons.style'

const ArrowDown = ({ color, width, height }) => {
  return (
    <Svg color={color || ''} xmlns='http://www.w3.org/2000/svg' width={width || '11.494'} height={height || '6.551'} viewBox='0 0 11.494 6.551'>
      <g id='back' transform='rotate(-90 30.33 30.033)'>
        <path id='Chevron_Right' d='M54.273 5.839l4.9 4.9a.55.55 0 1 0 .779-.777L55.44 5.45 59.951.94a.55.55 0 1 0-.779-.778l-4.9 4.9a.555.555 0 0 0 .001.777z' className='cls-1' />
      </g>
    </Svg>
  )
}

ArrowDown.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

export default ArrowDown
