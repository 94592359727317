import React from 'react'
import { BackToTopButton } from './BackToTop.style'
import Arrow from '../Icons/Arrow'

const BackToTop = () => {
  return (
    <BackToTopButton href="#">
      <Arrow direction="top" height="25px" width="25px" color="#ffffff" />
    </BackToTopButton>
  )
}

export default BackToTop
