import React from 'react'

const Trash = ({ color, width, height }) => {
  return (

    <svg width="16" height="21" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.71099 0.845094H7.28897V1.23881H8.21997V0.789981C8.22008 0.354398 7.82984 0 7.35017 0H4.64978C4.17011 0 3.77987 0.354398 3.77987 0.789981V1.23881H4.71099V0.845094Z" fill="#CCCCCC"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.3706 4.58689H1.6304C1.39092 4.58689 1.20238 4.77231 1.22168 4.98903L1.95238 13.1896C1.99309 13.6474 2.41382 14 2.91928 14H9.08157C9.58702 14 10.0077 13.6474 10.0485 13.1895L10.7792 4.98903C10.7986 4.77231 10.6101 4.58689 10.3706 4.58689ZM3.74671 13.1253C3.73695 13.1258 3.72718 13.1262 3.71752 13.1262C3.47345 13.1262 3.26856 12.9537 3.25337 12.7296L2.79546 5.99734C2.77969 5.76437 2.97493 5.5639 3.23148 5.54959C3.48721 5.53548 3.70904 5.71226 3.72481 5.94532L4.18261 12.6776C4.1985 12.9105 4.00326 13.1109 3.74671 13.1253ZM6.47122 12.7036C6.47122 12.9369 6.2628 13.1261 6.00566 13.1261C5.74851 13.1261 5.5401 12.9369 5.5401 12.7036V5.97128C5.5401 5.7379 5.74851 5.54872 6.00566 5.54872C6.26268 5.54872 6.47122 5.7379 6.47122 5.97128V12.7036ZM9.2055 5.99616L8.7683 12.7284C8.75382 12.9529 8.54858 13.1261 8.30403 13.1261C8.29486 13.1261 8.28555 13.1258 8.27626 13.1254C8.01959 13.1116 7.82377 12.9117 7.83894 12.6787L8.27603 5.94639C8.29109 5.71343 8.51069 5.53569 8.76806 5.54948C9.02473 5.56315 9.22056 5.7632 9.2055 5.99616Z" fill="#CCCCCC"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.98 3.2833L11.6743 2.45144C11.5936 2.23216 11.3675 2.08423 11.1127 2.08423H0.887206C0.632538 2.08423 0.406233 2.23216 0.325733 2.45144L0.0199905 3.2833C-0.0389688 3.44373 0.0377592 3.60737 0.180981 3.68896C0.239355 3.72218 0.308432 3.74215 0.384341 3.74215H11.6157C11.6916 3.74215 11.7608 3.72218 11.819 3.68886C11.9622 3.60725 12.039 3.44362 11.98 3.2833Z" fill="#CCCCCC"/>
    </svg>

  )
}


export default Trash