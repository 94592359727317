import React from 'react'
import PropTypes from 'prop-types'
import { Svg } from '../Icons.style'

const Check = ({ color, width, height, checked = true }) => {
  return (
    <Svg
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      width={width || '7.543'}
      height={height || '5.625'}
      viewBox="0 0 7.543 5.625"
    >
      {checked ? (
        <path
          id="check"
          d="M2.562 70.613L.111 68.161a.377.377 0 0 1 0-.533l.533-.533a.377.377 0 0 1 .533 0l1.652 1.652 3.537-3.537a.377.377 0 0 1 .533 0l.533.533a.377.377 0 0 1 0 .533L3.1 70.613a.377.377 0 0 1-.533 0z"
          className="cls-1"
          transform="translate(0 -65.098)"
        />
      ) : (
        <path
          id="check"
          d="M2.562 .533 0l.533.533a.377.377 0 0 1 0  0z"
          className="cls-1"
          transform="translate(0 -65.098)"
        />
      )}
    </Svg>
  )
}

Check.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default Check
