import React from 'react'
import { WarningContainer, WarningText, WarningDesc } from './Warning.style'
import { Warning as WarningIcon } from 'components/Icons'
import Stock from 'components/Icons/Stock'

const Warning = props => {
  const { color = 'red', icon = 'warning', iconSize = 16 } = props
  const icons = {
    warning: <WarningIcon size={iconSize} />,
    check: <Stock size={iconSize} />,
    none: <div></div>,
  }
  const colors = {
    red: 'rgb(255 188 66 / 14%)',
    green: 'rgb(195 226 198 / 41%)',
    yellow: 'rgb(255 188 66 / 14%)',
  }

  return (
    <WarningContainer style={{ background: colors[color] }}>
      {icons[icon]}
      <WarningDesc>{props.description}</WarningDesc>
      <WarningText>{props.warning}</WarningText>
    </WarningContainer>
  )
}

export default Warning
