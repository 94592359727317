import { BrowserView, MobileView } from 'components/DeviceDetect'
import { ArrowLeft, ArrowRight, Burger } from 'components/Icons'
import Close2 from '../../Icons/Close2'
import Arrow from 'components/Icons/Arrow'
import categoryUrl from 'helpers/categoryUrl'
import translate from 'helpers/translate'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import useInnerPage from 'store/InnerPageProvider'
import {
  CategoriesHeader,
  CategoriesLI,
  CategoriesUL,
  CategoriesWrapper,
  CatHeaderText,
  CatHeaderTitle,
  DesktopHeaderCategoriesContainer,
  DesktopHeaderCategoriesItem,
  DesktopHeaderCategoriesLeft,
  DesktopHeaderCategoriesRight,
  HomeCategoriesHeader,
  SubCategoryContainer,
  SubCategoryList,
  SubCategoryLists,
} from 'style/CategoryList/CategoryList.style'

const SingleCategory = ({ category, onClick }) => {
  const single = (category.children || []).length === 0
  const badge = category.badge ? (
    <span className="menu-badge">{category.badge}</span>
  ) : (
    ''
  )

  if (category.slug) {
    return (
      <Link
        onClick={onClick}
        className={single ? 'single-cat' : ''}
        to={categoryUrl(category.slug)}
      >
        {category.title || ''} {badge}
      </Link>
    )
  }

  return (category.url || '').indexOf('http') > -1 ? (
    <Link
      onClick={onClick}
      className={single ? 'single-cat' : ''}
      to={category.url}
    >
      {category.title || ''} {badge}
    </Link>
  ) : (
    <Link
      onClick={onClick}
      className={single ? 'single-cat' : ''}
      to={categoryUrl(category.url)}
    >
      {category.title || ''} {badge}
    </Link>
  )
}

const SubCategory = props => {
  const ref = useRef(null)

  useEffect(() => {
    const firstLi = document.querySelector(
      '[type="desktop"] #categoriesul > li:first-child'
    )
    let fullWidth = document.querySelector('#homeslider')?.offsetWidth
    if (!fullWidth) {
      fullWidth = document.querySelector('#content-container')?.offsetWidth
    }
    const catWidth = document.querySelector(
      '[type="desktop"] #categoriesul'
    )?.offsetWidth
    const catHeight = document.querySelector(
      '[type="desktop"] #categoriesul'
    )?.offsetHeight
    if (!firstLi || !ref.current.offsetParent || !ref.current) return
    const offsetTop = firstLi.offsetTop
    const currentOffestTop = ref.current.offsetParent.offsetTop
    ref.current.style.marginTop = `${offsetTop - currentOffestTop - 3}px`
    ref.current.style.width = `${fullWidth - catWidth}px`
    ref.current.querySelector(':scope > *').style.minHeight = `${catHeight}px`
  }, [])

  return (
    <SubCategoryContainer
      className="sub-menu"
      ref={ref}
      withoutStyles={props.withoutStyles}
    >
      <SubCategoryLists>
        {(props.data || []).map(item => {
          return (
            <SubCategoryList key={item.id}>
              <h3 className={item.children.length === 0 ? 'no-children' : ''}>
                <SingleCategory
                  onClick={props.onClick}
                  className="catText"
                  category={item}
                />
              </h3>
              <ul>
                {(item.children || []).slice(0, 6).map(list => {
                  return (
                    <li key={list.id}>
                      <SingleCategory
                        onClick={props.onClick}
                        className="catText"
                        category={list}
                      />
                    </li>
                  )
                })}
              </ul>
            </SubCategoryList>
          )
        })}
        {props.img ? <img src={props.img} alt="Category" /> : null}
      </SubCategoryLists>
    </SubCategoryContainer>
  )
}

export const CategoriesContent = ({
  withoutStyles,
  open,
  categories,
  topCategories,
  mobileTop,
  homeDesktop,
  onClick,
  productPage,
}) => {
  if (!Array.isArray(categories)) return null

  return (
    <CategoriesUL
      id="categoriesul"
      key={categories.length}
      productPage={productPage}
      open={open}
      mobileTop={mobileTop}
      homeDesktop={homeDesktop}
    >
      {categories.map((category, index) => (
        <CategoriesLI
          homeDesktop={homeDesktop}
          withoutStyles={withoutStyles}
          key={index}
          css={category.style}
        >
          {homeDesktop && (
            <img className="catIcon" src={category.icon} alt="icon" />
          )}
          <SingleCategory onClick={onClick} category={category} />
          {category.children && category.children.length !== 0 ? (
            <img src="/svg/cat-left-arrow-gray.svg" alt="" />
          ) : // <ArrowRight onClick={onClick} color="black1" />
          null}
          {category.count ? <span> {category.count}</span> : null}
          {(category.children || []).length ? (
            <SubCategory
              img={category.image}
              onClick={onClick}
              data={category.children}
              withoutStyles={withoutStyles}
            />
          ) : null}
        </CategoriesLI>
      ))}
    </CategoriesUL>
  )
}

export const MobileSubCategoriesContent = ({
  hidden,
  open,
  setOpen,
  categories,
  topCategories,
  isFirst,
  openedCategories,
  setOpenedCategories,
}) => {
  if (!Array.isArray(categories.children)) return null

  function onClick() {
    setOpen(false)
    setOpenedCategories([-1])
  }

  function openCategory(id) {
    if (typeof id !== 'undefined')
      setOpenedCategories([...openedCategories, id])
  }

  function popCategory() {
    setOpenedCategories(openedCategories.slice(0, openedCategories.length - 1))
  }

  return (
    <CategoriesUL
      className={`category-ul-mobile ${hidden ? 'hidden' : ''}`}
      key={categories.children.length}
      open={open}
      mobileTop
    >
      {!isFirst && (
        <CategoriesLI mobileTop backButton key={-1}>
          <div className="back-button" onClick={popCategory}>
            <ArrowLeft color="#444c58" width="15px" height="15px" />
            <div>{categories.title}</div>
          </div>
        </CategoriesLI>
      )}
      {categories.children.map((category, index) => (
        <CategoriesLI key={index} css={category.style} mobileTop>
          {category.children && category.children.length !== 0 ? (
            <div
              className="mobile-li-div"
              onClick={() => openCategory(category.id)}
            >
              {isFirst ? (
                <img
                  className="category-mobile-img"
                  src={category.icon}
                  alt="category icon"
                />
              ) : null}
              {category.title}
              {category.children && category.children.length !== 0 ? (
                <img
                  className="right-arrow-mobile"
                  src="/svg/cat-left-arrow-gray.svg"
                  alt=""
                />
              ) : null}
            </div>
          ) : (
            <SingleCategory onClick={onClick} category={category} />
          )}
        </CategoriesLI>
      ))}
    </CategoriesUL>
  )
}

// added to avoid conflict on certain pages
export const InnerCategoriesContent = ({
  withoutStyles,
  open,
  onClick,
  categories,
  mobileTop,
  homeDesktop,
}) => {
  if (!Array.isArray(categories)) return null

  return (
    <CategoriesUL
      id="categoriesul"
      key={categories.length}
      open={open}
      mobileTop={mobileTop}
      homeDesktop={homeDesktop}
    >
      {categories.map((category, index) => (
        <CategoriesLI
          homeDesktop={homeDesktop}
          withoutStyles={withoutStyles}
          key={index}
          css={category.style}
        >
          {homeDesktop && (
            <img className="catIcon" src={category.icon} alt="icon" />
          )}
          <SingleCategory onClick={onClick} category={category} />
          {category.children && category.children.length !== 0 ? (
            <img src="/svg/cat-left-arrow-gray.svg" alt="" />
          ) : null}
          {category.count ? <span> {category.count}</span> : null}
          {(category.children || []).length ? (
            <SubCategory
              data={category.children}
              withoutStyles={withoutStyles}
            />
          ) : null}
        </CategoriesLI>
      ))}
    </CategoriesUL>
  )
}

export const MobileCategoriesContent = ({ open, setOpen, categories }) => {
  const FIRST_CATEGORY_ID = -1

  const [openedCategories, setOpenedCategories] = useState([FIRST_CATEGORY_ID])
  if (!Array.isArray(categories)) return null

  function flattenCategories(category) {
    let currCategory = category

    if (typeof category.id === 'undefined') {
      currCategory = {
        id: FIRST_CATEGORY_ID,
        children: category,
      }
    }

    const flat = [currCategory]

    currCategory.children.forEach(category => {
      if (Array.isArray(category.children) && category.children.length > 0) {
        flat.push(...flattenCategories(category))
      }
    })

    return flat
  }

  return (
    <>
      {flattenCategories(categories).map((category, index) => {
        return (
          <MobileSubCategoriesContent
            hidden={
              category.id !== openedCategories[openedCategories.length - 1]
            }
            open={open}
            setOpen={setOpen}
            categories={category}
            isFirst={category.id === FIRST_CATEGORY_ID}
            openedCategories={openedCategories}
            setOpenedCategories={setOpenedCategories}
          />
        )
      })}
    </>
  )
}

export const CategoriesDesktop = ({
  withoutStyles,
  title,
  categories,
  topCategories,
  withoutTitle,
  titleComponent,
  innerPage,
  ...rest
}) => {
  return (
    <CategoriesWrapper
      innerPage={innerPage}
      withoutStyles={withoutStyles}
      type="desktop"
      className="desktop-category"
      {...rest}
    >
      {!withoutTitle && (
        <CategoriesHeader withoutStyles={withoutStyles}>
          <CatHeaderTitle withoutStyles={withoutStyles}>
            <Burger width="16" height="12" color="black1" />
            {title || translate('categories')}
          </CatHeaderTitle>
        </CategoriesHeader>
      )}
      {titleComponent && titleComponent}

      <CategoriesContent
        categories={categories}
        open
        withoutStyles={withoutStyles}
        onClick={e => {}}
      />
    </CategoriesWrapper>
  )
}

export const DesktopHeaderCategories = ({
  withoutStyles,
  title,
  categories,
  topCategories,
  withoutTitle,
  titleComponent,
  innerPage,
  productPage,
  ...rest
}) => {
  const HeaderCategoriesList = topCategories
  const [open, setOpen] = useState(false)

  return (
    <BrowserView>
      <DesktopHeaderCategoriesContainer>
        <DesktopHeaderCategoriesLeft
          onClick={() => setOpen(!open)}
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
        >
          <HomeCategoriesHeader>
            <CatHeaderTitle homeDesktop={true}>
              <Burger color="white" />
              <p>ყველა კატეგორია</p>
            </CatHeaderTitle>
          </HomeCategoriesHeader>

          <CategoriesContent
            open={open}
            onClick={() => {
              setOpen(false)
            }}
            categories={categories}
            homeDesktop={true}
            productPage={productPage}
          />
        </DesktopHeaderCategoriesLeft>
        <DesktopHeaderCategoriesRight>
          {HeaderCategoriesList.map(cat => (
            <DesktopHeaderCategoriesItem href={categoryUrl(cat.url)}>
              <img src={cat.icon} alt="" />
              <p>{cat.title}</p>
            </DesktopHeaderCategoriesItem>
          ))}
        </DesktopHeaderCategoriesRight>
      </DesktopHeaderCategoriesContainer>
    </BrowserView>
  )
}

const Categories = ({
  withoutStyles,
  withoutTitle,
  title,
  categories,
  mobileTop,
}) => {
  const [open, setOpen] = useState(false)
  const { category } = useInnerPage()

  const Icon = React.memo(({ open }) => {
    return open ? (
      <Close2 color="#ff2337" width="20px" height="20px" />
    ) : (
      <Burger color="#ff2337" />
    )
  })

  if (!Array.isArray(categories) || !categories.length)
    return (
      <MobileView>
        <div className="my-2 category-back-title">
          {category && category.parent ? (
            <Link to={categoryUrl(category.parent.slug)}>
              <Arrow direction="left" /> {category.parent.title}
            </Link>
          ) : null}
        </div>
      </MobileView>
    )

  if (mobileTop)
    return (
      <CategoriesWrapper withoutStyles={withoutStyles} mobileTop={true}>
        <CategoriesHeader
          onClick={() => setOpen(!open)}
          withoutStyles={withoutStyles}
        >
          <Icon open={open} />
        </CategoriesHeader>
        <MobileCategoriesContent
          open={open}
          setOpen={setOpen}
          mobileTop={true}
          categories={categories}
          onClick={e => {
            const target = e.target.closest('li')
            target.classList.toggle('active')
          }}
        />
      </CategoriesWrapper>
    )

  return (
    <CategoriesWrapper withoutStyles={withoutStyles}>
      <CategoriesHeader
        onClick={() => setOpen(!open)}
        withoutStyles={withoutStyles}
      >
        {!withoutTitle && (
          <Fragment>
            <CatHeaderTitle withoutStyles={withoutStyles}>
              <Burger height="13" />
              {/* {title || translate('categories')} */}
              კატეგორიები
            </CatHeaderTitle>
            <CatHeaderText>
              <ArrowRight color="black" />
            </CatHeaderText>
          </Fragment>
        )}
      </CategoriesHeader>
      <InnerCategoriesContent
        open={open}
        categories={categories}
        onClick={() => setOpen(false)}
      />
    </CategoriesWrapper>
  )
}

export default Categories
