import { useEffect } from 'react'

const useOutsideAlerter = (ref, call) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                call()
            }
        }

        // Bind the event listener
        document.addEventListener("click", handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("click", handleClickOutside)
        };
    }, [ref, call])
}

export default useOutsideAlerter