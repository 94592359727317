import React from 'react'
import { Svg } from '../Icons.style'

const Gift = ({ color, width, height }) => {
  return (
    <Svg
      color={color}
      width={width || '16'}
      height={height || '14'}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          class="st0"
          d="M4.7,15.7c-0.3-0.1-0.3-0.3-0.3-0.6c0-1.8,0-3.6,0-5.4c0-0.1,0-0.2,0.1-0.2c1-0.6,2.1-1.2,3.1-1.8
		C7.7,7.6,7.9,7.6,8,7.5c0,0.1,0,0.2,0,0.2c0,1.6,0,3.1,0,4.7c0,0.2,0,0.3,0.2,0.4c0.2,0.2,0.4,0.2,0.6,0c0.1-0.1,0.2-0.2,0.3-0.3
		c0.1-0.1,0.2-0.2,0.3-0.3c0.2,0.2,0.3,0.3,0.4,0.4c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.3,0.2,0.5,0.1c0.2-0.1,0.3-0.2,0.3-0.4
		c0-0.8,0-1.6,0-2.5c0-0.8,0-1.6,0-2.5c0.1,0,0.1,0,0.2,0c1,0,1.9,0,2.9,0c0.4,0,0.5,0.2,0.5,0.5c0,2.3,0,4.7,0,7
		c0,0.3-0.1,0.5-0.3,0.6C10.9,15.7,7.8,15.7,4.7,15.7z"
        />
        <path
          class="st0"
          d="M1.7,7.1C1.7,6.9,1.9,6.8,2,6.7C2.4,6.5,2.9,6.2,3.3,6c0,0-0.1-0.1-0.1-0.1c-0.8-0.6-1-1.6-0.5-2.4
		c0.5-0.8,1.6-1.1,2.4-0.6c0.4,0.2,0.7,0.5,1.1,0.7c0,0,0.1,0,0.1,0.1c0-0.5,0-0.9,0-1.4c0-0.9,0.5-1.6,1.3-1.9
		C8.7,0.1,9.8,0.9,9.9,2c0,0,0,0.1,0,0.1C10,2.1,10.1,2,10.2,2c0.3-0.2,0.6-0.4,1-0.6c0.3-0.2,0.5-0.1,0.7,0.2
		c0.3,0.5,0.6,1,0.8,1.4c0.2,0.3,0.1,0.5-0.2,0.7c-3,1.7-6,3.4-8.9,5.2C3.5,9,3.4,9,3.3,9.1C3.1,9.2,2.8,9.2,2.7,9
		C2.3,8.4,2,7.8,1.7,7.3C1.7,7.2,1.7,7.1,1.7,7.1z M7.3,3.7c0.5-0.3,0.9-0.5,1.3-0.8C9,2.7,9.1,2.2,8.9,1.8C8.8,1.4,8.4,1.2,8,1.2
		C7.5,1.3,7.2,1.7,7.2,2.1C7.2,2.6,7.2,3.2,7.3,3.7z M5.9,4.5C5.9,4.4,5.9,4.4,5.9,4.5C5.5,4.2,5.1,3.9,4.7,3.6
		C4.5,3.6,4.4,3.5,4.3,3.5c-0.4,0-0.7,0.2-0.9,0.5c-0.2,0.4-0.1,0.8,0.2,1c0.3,0.3,0.7,0.3,1,0.1C5.1,5,5.5,4.7,5.9,4.5
		C5.9,4.5,5.9,4.5,5.9,4.5z"
        />
        <path
          class="st0"
          d="M8.9,7.6c0.3,0,0.6,0,0.9,0c0,1.3,0,2.5,0,3.8c-0.4-0.3-0.5-0.3-0.9,0C8.9,10.1,8.9,8.9,8.9,7.6z"
        />
      </g>
    </Svg>
    /* 
    <Svg color={color} xmlns='http://www.w3.org/2000/svg' width={width || '16'} height={height || '14'} viewBox='0 0 16 14'>
      <path id='gift' d='M1,45a1,1,0,0,0,1,1H7V41H1Zm14-9H13.684A2.725,2.725,0,0,0,14,34.75,2.754,2.754,0,0,0,11.25,32c-1.3,0-2.141.666-3.219,2.134C6.953,32.666,6.113,32,4.813,32a2.754,2.754,0,0,0-2.75,2.75A2.687,2.687,0,0,0,2.378,36H1a1,1,0,0,0-1,1v2.5a.5.5,0,0,0,.5.5h15a.5.5,0,0,0,.5-.5V37A1,1,0,0,0,15,36ZM4.809,36a1.25,1.25,0,0,1,0-2.5c.622,0,1.081.1,2.691,2.5Zm6.441,0H8.559c1.606-2.391,2.053-2.5,2.691-2.5a1.25,1.25,0,0,1,0,2.5ZM9,46h5a1,1,0,0,0,1-1V41H9Z' transform='translate(0 -32)' />
    </Svg> */
  )
}

export default Gift
