import React, { createContext, useContext, useEffect, useState } from 'react'

const FilterContext = createContext()
const defaultFilters = {
  min_price: null,
  max_price: null,
  attrs: {},
  ordering: '',
}

export const FilterContextProvider = ({ children }) => {
  const [filters, setFilters] = useState({ ...defaultFilters })

  const applyFilter = (key, value) => {
    console.log(key, value)
    setFilters(old => {
      old[key] = value
      return { ...old }
    })
  }

  const applyAttributeFilter = (id, values) => {
    setFilters(old => {
      old.attrs[id] = values
      return { ...old }
    })
  }

  useEffect(() => {
    if (Object.keys(filters).length === 0) return
  }, [filters])

  const nullFilter = () => {
    setFilters({ ...defaultFilters })
  }

  return (
    <FilterContext.Provider
      value={{
        filters: filters,
        applyAttributeFilter: applyAttributeFilter,
        applyFilter: applyFilter,
        nullFilter: nullFilter,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}

const useFilter = () => useContext(FilterContext)

export default useFilter
