import {
  CheckboxInput,
  LabelWithInput,
  Radio,
} from 'components/Input/Input.style'
import PropTypes from 'prop-types'
import React from 'react'

const CustomRadio = props => {
  const { type: _, ...rest } = props

  return (
    <LabelWithInput>
      <CheckboxInput {...rest} type="radio" />
      <Radio circleType={props.circleType} />
      {props.title}
    </LabelWithInput>
  )
}

CustomRadio.propTypes = {
  circleType: PropTypes.string,
  title: PropTypes.string,
}

export default CustomRadio
