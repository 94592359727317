import React from 'react'
import PropTypes from 'prop-types'
import { Svg } from '../Icons.style'

const Filter = ({ color, width, height }) => {
  return (
    <Svg color={color} xmlns='http://www.w3.org/2000/svg' width={width || '16'} height={height || '16.83'} viewBox='0 0 16 16.83'>
      <path id='Path_3172' data-name='Path 3172' d='M25.828,0H10.408a.287.287,0,0,0-.231.462l5.651,7.92a2.063,2.063,0,0,1,.4,1.218v6.912c.021.231.21.378.4.294l3.214-1.218a.253.253,0,0,0,.168-.252V9.6a2.112,2.112,0,0,1,.4-1.218L26.059.462A.287.287,0,0,0,25.828,0Z' transform='translate(-10.118)' fill='#5a6679' />
    </Svg>
  )
}

Filter.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

export default Filter
