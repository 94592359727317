import React, { createContext, useContext, useState } from 'react'

const CacheContext = createContext()

export const CacheContextProvider = ({ cache, children }) => {
  const [Cache, setCache] = useState(cache)

  const getCache = (name, def = null) => {
    if (!Cache) return def
    if (typeof Cache[name] === 'undefined') return def
    // console.log('got cache', name, Cache[name])

    return Cache[name]
  }

  return (
    <CacheContext.Provider
      value={{
        getCache: getCache,
      }}
    >
      {children}
    </CacheContext.Provider>
  )
}

const useCache = () => useContext(CacheContext)

export default useCache
