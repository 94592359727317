import { DeviceDetectProvider } from 'components/DeviceDetect'
import React, { useEffect } from 'react'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import { renderRoutes, routes } from 'route'
import { AuthContextProvider } from 'store/AuthContext'
import { CacheContextProvider } from 'store/CacheProvider'
import { CartContextProvider } from 'store/CartProvider'
import { ConfigContextProvider } from 'store/ConfigProvider'
import { FilterContextProvider } from 'store/FilterProvider'
import { InnerPageContextProvider } from 'store/InnerPageProvider'
import { OrdersContextProvider } from 'store/OrdersProvider'
import { SingleProductContextProvider } from 'store/SingleProductProvider'
import './App.css'
import BackToTop from './components/BackToTop/BackToTop'
import { AffilateContextProvider } from 'store/AffilateContext'

function App(props) {
  let cache = props.cache

  if (!cache && typeof window != 'undefined') {
    cache = window.cache
  }

  return (
    <DeviceDetectProvider>
      <CacheContextProvider cache={cache}>
        <AuthContextProvider>
          <AffilateContextProvider>
            <ConfigContextProvider>
              <OrdersContextProvider>
                <SingleProductContextProvider>
                  <CartContextProvider>
                    <FilterContextProvider>
                      <InnerPageContextProvider>
                        {renderRoutes(routes)}
                        <BackToTop />
                      </InnerPageContextProvider>
                    </FilterContextProvider>
                  </CartContextProvider>
                </SingleProductContextProvider>
              </OrdersContextProvider>
            </ConfigContextProvider>
          </AffilateContextProvider>
        </AuthContextProvider>
      </CacheContextProvider>
    </DeviceDetectProvider>
  )
}

export default App
