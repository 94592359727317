import React from 'react'
import PropTypes from 'prop-types'
import { Svg } from '../Icons.style'

const Close = ({ color, width, height }) => {
  return (
    <Svg color={color} xmlns='http://www.w3.org/2000/svg' width={width || '16'} height={height || '15.999'} viewBox='0 0 16 15.999'>
      <g className='cls-1' transform='translate(0 -.001)'>
        <path d='M9.415 8l6.292-6.292A1 1 0 0 0 14.292.294L8 6.586 1.708.294A1 1 0 0 0 .293 1.709L6.585 8 .293 14.293a1 1 0 1 0 1.415 1.415L8 9.415l6.292 6.292a1 1 0 0 0 1.415-1.415z' />
      </g>
    </Svg>
  )
}

Close.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

export default Close
