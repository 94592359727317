import useAuth from 'store/AuthContext'

const useIfCanSendOrder = () => {
  return true
}

export const incrementOrder = () => {
  localStorage.ord++
}

export default useIfCanSendOrder
