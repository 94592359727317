import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const BlogWrapper = styled.div`
    margin-top: 80px;    
    width: 100%;
    display: grid;
    grid-row-gap: 30px;
    padding: 50px 20px;
    @media (min-width: 1100px) {
        padding: 50px 0px;
        ${props => !props.withoutSidebar ? 'grid-template-columns: 6.5fr 2.5fr;' : ''}
        grid-column-gap: 30px;
    }
`

export const BlogContent = styled.div`
    display: grid;
    grid-row-gap: 15px;
`

export const BlogImage = styled.img`
    width: 100%;
    
    /* max-height: 450px; */
`

export const BlogTitle = styled.div`

    padding-bottom: 20px;
    border-bottom: 1px solid #ececec;

    h2 {
        font-size: 24px;
        color: #333e48;
        margin: 0px;
    }

    p {
        color: #a3a3a3;
        font-size: 14px;
        margin-top: 15px;
        span.cat {
            padding-right: 1em;
            &::after {
                color: #9f9f9f;
                content: "|";
                display: inline-block;
                font-weight: 400;
                vertical-align: -.125em;
                font-weight: 900;
                font-size: 14px;
                padding-left: 1em;
                position: relative;
                top: -2px;
            }
        }
        
    }
`

export const Description = styled.div`
    p,blockquote {
        margin-bottom: 20px;
        color: #5A6679;
        font-size: 16px;
        line-height: 24px;
    }

    p.highlight {
        font-size: 24px;
        font-family: ${props => props.theme.font.family.medium};
        line-height: 1.314em;
    }

    blockquote {
        position: relative;
        padding-left: 25px;
        &::before {
            content: '';
            background-image: url('/img/blackquote.svg');
            width: 21.33px;
            height: 18.29px;
            color: #a7a7a7;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            display: inline-block;
            font-weight: 900;
            font-size: 40px;
            position: absolute;
            left: -7px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

`

export const DescriptionRow = styled.div`
    display: grid;
    grid-row-gap: 25px;
    @media screen and (min-width: 1100px) {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 25px;
    }
`

export const BlogSideBar = styled.div`
    display: grid;
    grid-template-rows: auto 1fr;
    grid-row-gap: 15px;

    h3 {
        font-size: 22px;
        color: #394455;
        padding-bottom: 20px;
        margin: 0px;
        font-family: ${props => props.theme.font.family.medium};
        border-bottom: 1px solid #ececec;
    }
`

export const BlogAbout = styled.div`
    p {
        color: #798393;
        font-size: 14px;
        margin-bottom: 0px;
        margin-top: 20px;
        line-height: 18px;
        font-family: ${props => props.theme.font.family.light};
    }
`

export const RecentPosts = styled.div`
    width: 100%;
    
`

export const Posts = styled.div`
    display: grid;
    grid-row-gap: 15px;
    grid-template-rows: auto 1fr;
`

export const Post = styled(Link)`
    text-decoration: none;
    display: grid;
    grid-template-columns: 75px 1fr;
    grid-column-gap: 10px;

    img {
        width: 100%;
    }
`

export const PostInfo = styled.div`
    h2 {
        color: #434343;
        font-size: 12px;
        margin: 0px;
        font-family: ${props => props.theme.font.family.medium};
    }
    p {
        font-size: 12px;
        color: rgba(57,68,85,0.5);
        margin: 0px;
        margin-top: 10px;
        font-family: ${props => props.theme.font.family.medium};
    }
`
