import styled from 'styled-components'

export const HeaderContainer = styled.header`
  grid-area: header;
`

export const FooterContainer = styled.footer`
  grid-area: footer;
  background-color: #082032;

  @media screen and (min-width: 1100px) {
  }
`

export const Container = styled.div`
  width: 100%;
  @media screen and (min-width: 1100px) {
    width: 100%;
    max-width: 1100px;
    margin: auto;
  }

  @media screen and (min-width: 1250px) {
    max-width: 1250px;
  }

  @media screen and (min-width: 1400px) {
    max-width: 1390px;
  }
`

export const HalfContainer = styled.div`
  width: 100%;
  a.social {
    filter: brightness(0.2);
  }
  @media screen and (min-width: 1100px) {
    width: 100%;
    max-width: 480px;
    margin: auto;
  }

  @media screen and (min-width: 1400px) {
    max-width: 630px;
  }
`

export const Content = styled.div`
  grid-area: content;
  min-height: 50vh;   
  @media screen and (max-width: 1100px){
  margin-top: 106px;
}
`
