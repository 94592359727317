import api from './api'

class UtilsService {
  static getInstallmentPriceFor(price) {
    return api
      .call('post', `/utils/installmentprice`, { price: price })
      .then(api.getData)
  }

  static sendReport(data) {
    return api.call('post', `/utils/save-report`, data).then(api.getData)
  }
}

export default UtilsService
