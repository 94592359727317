import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Input from 'components/Input'

export const HeaderContainer = styled.div`
  width: 100%;
  padding: 17px 16px 19px 16px;
  background-color: #F2F4F8;
  box-sizing: border-box;

  @media screen and (min-width: 1100px) {
    padding: 15px 0px;
    border-bottom: 1px solid ${props => props.theme.color.greyED};
    background-color: transparent;
  }
`

export const HeaderTop = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 18px;
  align-items: center;

  @media screen and (min-width: 1100px) {
    display: flex;
    justify-content: space-between;
  }

`

export const HeaderBottom = styled.div`
  margin-top: 20px;
  @media screen and (min-width: 1100px) {
    display: none;
  }
`

export const HeaderLogo = styled.div`
  cursor: pointer;
  img {
    max-width: 160px;
  }
`

export const BurgerMenu = styled.div`
  @media screen and (min-width: 1100px) {
    display: none;
  }

`

export const HeaderSearch = styled(Input)`
  &:focus,
  select:focus,
  textarea:focus,
  button:focus {
    border-color: #ff2337;
    outline: none;
  }
  border: 2px solid #ff2337;
  @media screen and (min-width: 1100px) {
    width: 510px !important;
  }
  @media screen and (min-width: 1251px) {
    min-width: 680px !important;
  }
  @media screen and (min-width: 1400px) {
    width: 800px !important;
  }
  button{
    color: red;
  }
  
`

export const HotLine = styled.div`
  @media screen and (min-width: 1100px) {
    display: flex;
    align-items: center;
    margin-right: 42px;
    position: relative;
    margin-left: -20px;

    i {
      width: 24px;
      height: 24px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      font-size: 20px;
      color: ${props => props.theme.color.black1};
      margin-left: 10px;
      font-family: Roboto-Bold;
      cursor: pointer;
      span {
        display: block;
        font-size: ${props => props.theme.font.size.xs};
      }
    }
  }

`

export const HotlineBoxWrapper = styled.div`
  @media screen and (min-width: 1100px) {
    padding-top: 14.9px;
    visibility: ${props => props.show ? 'visible' : 'hidden'};
    opacity: ${props => props.show ? '1' : '0'};
    transition: all 0.6s ease;
    position: absolute;
    transform:translateY(-45px);
    margin: 0 -15px;
    top: 15px;
    z-index: 999;
  }
`

export const HotLineBox = styled.div`
  @media screen and (min-width: 1100px) {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-row-gap: 35px;
    padding: 15px;
    padding-bottom: 24px;
    min-height: 244px;
    
    background: white;
  }
  
`

export const HotLineNumber = styled.div`
  display: flex;
  align-items: center;
  margin: 0px;
  i {
    width: 34px;
    height: 34px;
    background-color: rgba(255,167,1,0.1);
    svg {
      width: 20px;
      height: 20px;
    }
  }

  p {
    font-size: 18px;
    color: #EB3E3E;
    cursor: normal;
    span {
      color: black;
      font-weight: bold;
      font-size: 12px;
      display: block;
    }
  }
`

export const HotLineForm = styled.form`

  display: grid;
  grid-row-gap: 8px;

  h3 {
    font-size: 10px;
    font-family: ${props => props.theme.font.family.light};
    color: #1A2738;
    margin: 0px;
  }

  .orange-button {
    width: 100%;
    max-width: 100%;
    border-radius: 5px;
    margin-top: 6px;
    font-size: 14px;
  }

`

export const HotLineInput = styled(Input)`
  width: 100%;
  border: 1px solid #DFE3E9;
  border-radius: 5px;
  padding: 10px 8px;
  font-size: 16px;
`

const headerAccountStyle = (props) => `
  /* margin-right: 15px; */
  p {
    display: none;
  }
  @media screen and (min-width: 1100px) {
    text-decoration: none;

    div {
      display: flex;
      align-items: center;
      margin-right: 30px;
    }

    .user-icon {
      display: none;
    }

    p {
      display: block;
      margin-left: 13px;
      font-size: ${props.theme.font.size.sm};
      color: ${props.theme.color.black2};
      font-family: ${props.theme.font.family.medium};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 72px;
    }
  }
`

export const HeaderAccount = styled(Link)`
  ${props => headerAccountStyle(props)}
`

export const HeaderAccountButton = styled.button`
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  ${props => headerAccountStyle(props)}
`

export const HeaderIcon = styled.div`
  position: relative;
  span {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #ff2337;
    font-size: 10px;
    color: ${props => props.theme.color.white};
    display: flex;
    align-items: center;
    justify-content: center;
    right: -17%;
    font-family: ${props => props.theme.font.family.medium};
    top: 25%;
    transform: translateY(-50%);
  }

`

export const HeaderBasketIcon = styled(HeaderIcon)`
  padding-top: 5px;
  @media screen and (min-width: 1100px) {
    /* margin-left: 26px; */
    svg{
      height: 30px;
      width: 30px;
    }
  }

`

export const HeaderInfo = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-left: 16px;
  }
  @media screen and (min-width: 1100px) {
  }

`

export const HeaderCartBoxWrapper = styled.div`
  width: auto;
  position: absolute;
  padding-top: 20px;
  top: 15px;
  right: 50px;
  top: 70px;
  z-index: 999;
  display: ${props => props.show ? 'block' : 'none'};
  

  @media screen and (max-width: 1100px) {
    right: 50%;
    width: 95%;
    transform: translate(50%);
    margin: 0px;
  }

`

export const HeaderCartBox = styled.div`
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  padding-right: 0px;
  padding-bottom: 10px;
  border-top: 1px solid ${props => props.theme.color.orange};
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.28);
  h3 {
    width: 90%;
    font-size: 18px;
    color: ${props => props.theme.color.red2};
    font-family: ${props => props.theme.font.family.bold};
    text-align: center;
    white-space: nowrap;
    
    text-overflow: ellipsis;
    padding-right: 10px;
    overflow: hidden;
    
  }
  h2 {
    width: 100%;
    font-size: 14px;
    color: ${props => props.theme.color.grey1};
    font-family: ${props => props.theme.font.family.bold};
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
    overflow: hidden;

  }

`

export const HeaderBoxItems = styled.div`
  display: grid;
  grid-row-gap: 10px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 16px;
  //min-width: 300px;
  margin-right: 30px;
  max-height: 300px;
  overflow-y: auto;
 

`

export const HeaderCartBoxItem = styled(Link)`
  display: grid;
  grid-template-columns: 75px 1fr 25px;
  text-decoration: none;
  grid-column-gap: 20px;
  img {
    width: 75px;
  }

  @media screen and (max-width: 1100px) {
    grid-template-columns: 50px 1fr;
    align-items: center;
    position: relative;
    img {
      width: 50px;
    }

    button {
      position: absolute;
      right: 5px;
      top: 0px;
    }
  }
`

export const HeaderCartBoxItemInfo = styled.div`
  h3 {
    text-align: left;
    font-size: 12px;
    color: ${props => props.theme.color.red2};
    font-family: ${props => props.theme.font.family.bold};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 20px;
    overflow: hidden;
    @media screen and (max-width: 768px){
      max-width: 240px;
      padding-right: 10px;
    }
 
  }

  p {
    font-size: 16px;
    color: #373a3c;
    margin-top: 15px;
    @media screen and (max-width: 1100px) {
      margin-top: 7px;
    }
  }

`

export const HeaderCartBoxActions = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 20px;
  justify-content: center;
  margin-top: 20px;

  button{
    width: 250px;
    font-size: 18px;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .grey-button {
    border-radius: 40px;
  }

`
