import styled from 'styled-components'

export const LoaderContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${props => !props.notFull ? `
      min-height: calc(100vh - 200px);
    ` : ''}
    ${props => props.css ? props.css : ''}
    ${props => props.transparent ? `
      position: fixed;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      background: rgba(255, 255, 255, 0.8);
      z-index: 99999999999999999999;
    `: ''}
    ${props => props.fullScreen ? `
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      background: white;
      z-index: 99999999999999999999;
    `: ''}
`
