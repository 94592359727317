import qs from 'query-string'
import React, { createContext, useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useHistory } from 'react-router'
import ProductService from 'services/ProductService'
const queryParams = qs.parse(
  typeof window != 'undefined' ? window.location.search : ''
)

const getAttrFilters = () => {
  try {
    return JSON.parse(queryParams.filters)
  } catch {
    return {}
  }
}

const InnerPageContext = createContext()
const defaultSidebar = {
  min_price: queryParams.min_price,
  max_price: queryParams.max_price,
  attributes: {},
  categories: [],
  manufacturers: [],
  ordering: queryParams.ordering || '',
  loaded: false,
}
const defaultParams = {
  ordering: defaultSidebar.ordering,
  page: queryParams.page || 1,
  perPage: queryParams.perPage || 50,
  min_price: defaultSidebar.min_price || 0,
  max_price: defaultSidebar.max_price,
  attrs: getAttrFilters(),
}

export const InnerPageContextProvider = ({ children }) => {
  const [sidebar, SetSidebar] = useState({ ...defaultSidebar })
  const [productResolver, setProductResolver] = useState(null)
  const [onResolve, setOnResolve] = useState(null)
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(true)
  const [category, setCategory] = useState(true)
  const [params, setParams] = useState({ ...defaultParams })
  const [text, setText] = useState(null)
  const [additionalData, setAdditionalData] = useState({})
  const [disableAutoFilter, setDisableAutoFilter] = useState(false)
  const history = useHistory()

  const setSidebar = newSidebar => {
    ReactDOM.unstable_batchedUpdates(() => {
      SetSidebar({ ...sidebar, ...newSidebar, loaded: true })
      setLoading(false)
    })
  }

  const setParam = (key, value, type = null) => {
    setParams(old => {
      if (key !== 'page' && params.page !== 1) {
        old.page = 1
      }
      if (type) {
        old[type] = {
          ...old[type],
          [key]: value,
        }
      } else old[key] = value
      return { ...old }
    })
  }

  useEffect(() => {
    ProductService.params = params
    const filters =
      params.attrs && Object.keys(params.attrs).length
        ? JSON.stringify(params.attrs)
        : ''
    // moveToFirstPage()
    if (shouldAppendParams()) {
      history.replace({
        path: `${window.location.pathname}`,
        search: qs.stringify({
          ...queryParams,
          min_price: params.min_price,
          max_price: params.max_price,
          ordering: params.ordering,
          page: params.page,
          perPage: params.perPage,
          filters: filters,
        }),
      })
    }
    // eslint-disable-next-line
  }, [params])

  const onFilter = () => {
    if (!productResolver) return
    setLoading(true)
    productResolver()
      .then(data => {
        if (window.innerWidth > 680) {
          window.scrollTo(0, 0)
        }
        ReactDOM.unstable_batchedUpdates(() => {
          setLoading(false)
          if (data.sidebar) {
            setSidebar(data.sidebar)
            delete data.sidebar
          }
          setText(data.text)
          delete data.text
          if (data.products) {
            setProducts(data.products)
          }
          if (data.category) {
            setCategory(data.category)
          }
          if (typeof onResolve === 'function') {
            onResolve(data)
          }
        })
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (disableAutoFilter) return
    onFilter()
    // eslint-disable-next-line
  }, [productResolver, onResolve, params])

  const init = (options = {}) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setCategory({})
      setLoading(true)
      SetSidebar({ ...defaultSidebar })
      if (typeof options.resolver === 'function') {
        setProductResolver(() => options.resolver)
      }
      if (typeof options.onResolve === 'function') {
        setOnResolve(() => options.onResolve)
      }
    })
  }

  const clearFilters = () => {
    window.scrollTo(0, 0)
    setParams({
      ordering: defaultSidebar.ordering,
      page: 1,
      perPage: 15,
      attrs: {},
    })
  }

  useEffect(() => {
    clearFilters()
    // eslint-disable-next-line
  }, [category?.id])

  return (
    <InnerPageContext.Provider
      value={{
        sidebar: sidebar,
        params: params,
        loading: loading,
        productResolver: productResolver,
        category: category,
        products: products,
        text: text,
        additionalData,
        init: init,
        setAdditionalData,
        setSidebar: setSidebar,
        setParam: setParam,
        setCategory: setCategory,
        setLoading: setLoading,
        clearFilters: clearFilters,
        onFilter,
        setDisableAutoFilter,
      }}
    >
      {children}
    </InnerPageContext.Provider>
  )
}

const shouldAppendParams = () => {
  return (
    window.location.href.indexOf('/search') > -1 ||
    window.location.href.indexOf('/brand') > -1 ||
    window.location.href.indexOf('/category') > -1 ||
    window.location.href.indexOf('/promotion') > -1 ||
    window.location.href.indexOf('/promotion') > -1 ||
    window.location.href.indexOf('/sale') > -1
  )
}

const useInnerPage = () => useContext(InnerPageContext)

export default useInnerPage
