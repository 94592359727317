import React from 'react'
import PropTypes from 'prop-types'
import { Svg } from '../Icons.style'

const Plus = ({ color, width, height }) => {
  return (
    <Svg color={color} xmlns='http://www.w3.org/2000/svg' width={width || '14'} height={height || '14'} viewBox='0 0 14 14'>
      <path id='Plus' d='M10,8h5.074a1.033,1.033,0,0,1,.517.107.727.727,0,0,1,.3.3A1.033,1.033,0,0,1,16,8.926v.147a1.033,1.033,0,0,1-.107.517.727.727,0,0,1-.3.3,1.033,1.033,0,0,1-.517.107H10v5.074a1.033,1.033,0,0,1-.107.517.727.727,0,0,1-.3.3A1.033,1.033,0,0,1,9.074,16H8.926a1.033,1.033,0,0,1-.517-.107.727.727,0,0,1-.3-.3A1.033,1.033,0,0,1,8,15.074V10H2.926a1.033,1.033,0,0,1-.517-.107.727.727,0,0,1-.3-.3A1.033,1.033,0,0,1,2,9.074V8.926a1.033,1.033,0,0,1,.107-.517.727.727,0,0,1,.3-.3A1.033,1.033,0,0,1,2.926,8H8V2.926a1.033,1.033,0,0,1,.107-.517.727.727,0,0,1,.3-.3A1.033,1.033,0,0,1,8.926,2h.147a1.033,1.033,0,0,1,.517.107.727.727,0,0,1,.3.3A1.033,1.033,0,0,1,10,2.926Z' transform='translate(-2 -2)' fill={color||'#fff'} fillRule='evenodd' />
    </Svg>
  )
}

Plus.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

export default Plus
