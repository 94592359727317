import styled from 'styled-components'

export const StarsWrapper = styled.div`
  display: flex;
  img {
    &:not(:first-child)  {
      margin-left: 5px;
    }
  }
  @media screen and (min-width: 1100px) {

  }

`
