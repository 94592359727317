import React from 'react'


const Phone2 = ({ color, width, height }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30.035 22.594C29.982 22.55 23.986 18.284 22.367 18.545C21.586 18.683 21.14 19.216 20.245 20.282C20.101 20.454 19.754 20.865 19.486 21.158C18.9203 20.9737 18.3686 20.7491 17.835 20.486C15.0805 19.145 12.855 16.9195 11.514 14.165C11.2509 13.6314 11.0263 13.0797 10.842 12.514C11.136 12.245 11.548 11.898 11.724 11.75C12.785 10.86 13.317 10.413 13.455 9.631C13.738 8.012 9.45 2.018 9.406 1.964C9.21072 1.68706 8.95639 1.45692 8.66137 1.29022C8.36635 1.12351 8.03799 1.02439 7.7 1C5.962 1 1 7.436 1 8.521C1 8.584 1.091 14.988 8.988 23.021C17.012 30.909 23.416 31 23.479 31C24.563 31 31 26.038 31 24.3C30.9754 23.962 30.876 23.6336 30.7092 23.3386C30.5423 23.0436 30.312 22.7893 30.035 22.594ZM23.368 28.994C22.5 28.92 17.12 28.211 10.4 21.61C3.767 14.857 3.076 9.468 3.007 8.633C4.31778 6.57564 5.90079 4.70502 7.713 3.072C7.753 3.112 7.806 3.172 7.874 3.25C9.26383 5.14723 10.4611 7.17827 11.448 9.313C11.1271 9.63587 10.7878 9.94004 10.432 10.224C9.88019 10.6445 9.37348 11.121 8.92 11.646C8.84327 11.7537 8.78865 11.8755 8.7593 12.0044C8.72994 12.1333 8.72644 12.2667 8.749 12.397C8.96063 13.3137 9.28475 14.2008 9.714 15.038C11.2519 18.196 13.8038 20.7475 16.962 22.285C17.799 22.7149 18.6861 23.0393 19.603 23.251C19.7333 23.2741 19.8668 23.2708 19.9958 23.2414C20.1248 23.2121 20.2466 23.1572 20.354 23.08C20.8808 22.6247 21.3591 22.116 21.781 21.562C22.095 21.188 22.514 20.689 22.673 20.548C24.8132 21.534 26.8487 22.7327 28.749 24.126C28.832 24.196 28.891 24.25 28.93 24.285C27.2969 26.0978 25.4259 27.6812 23.368 28.992V28.994Z" fill="#EB3E3E"/>
      <path d="M23 15H25C24.9976 12.879 24.154 10.8456 22.6542 9.34578C21.1544 7.846 19.121 7.00238 17 7V9C18.5908 9.00159 20.116 9.63424 21.2409 10.7591C22.3658 11.884 22.9984 13.4092 23 15Z" fill="#EB3E3E"/>
      <path d="M28 15H30C29.996 11.5534 28.6251 8.24911 26.188 5.812C23.7509 3.37488 20.4466 2.00397 17 2V4C19.9163 4.00344 22.7122 5.16347 24.7744 7.22563C26.8365 9.28778 27.9966 12.0837 28 15Z" fill="#EB3E3E"/>
    </svg>

  )
}


export default Phone2
