import React from 'react'
import PropTypes from 'prop-types'
import { Svg } from '../Icons.style'

const WoundedArrow = ({ color, width, height }) => {
  return (
    <Svg stroke={color} xmlns='http://www.w3.org/2000/svg' width={width || '76.444'} height={height || '84.258'} viewBox='0 0 76.444 84.258'>
      <g transform='matrix(0.391, 0.921, -0.921, 0.391, 41.711, 0.404)'>
        <path d='M0,40.325C1.071,29.518,12.871,20.875,23.99,22.753c3.625.612,7.232,2.322,9.166,5.334s1.665,7.436-1.223,9.631a7.938,7.938,0,0,1-7.9.575,12.25,12.25,0,0,1-5.609-5.737c-3.809-7.486-1.465-17.02,4.427-23.131S37.568.356,46.228.033,63.459,1.785,71.665,4.464' transform='translate(0 3.979)' fill='none' stroke='#000' strokeMiterlimit='10' strokeWidth='3' />
        <path d='M0,15.265l16.232-.387L6.822,0' transform='translate(57.363 -4.553)' fill='none' stroke='#000' strokeMiterlimit='10' strokeWidth='3' />
      </g>
    </Svg>
  )
}

WoundedArrow.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

export default WoundedArrow
