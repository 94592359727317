import React from 'react'
import ReactCountDown from 'react-countdown'
import {
  CountDownWrapper,
  CountDownTime,
  SpecOfferCountDown,
  SpecOfferCountDownTime,
  RoundedCountDown,
} from './CountDown.style'

const withZero = number => {
  if (number.toString().length === 1) {
    return `0${number}`
  }
  return number
}

const SpecOfferRenderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return 'Finished'
  }

  return (
    <SpecOfferCountDown>
      <SpecOfferCountDownTime>
        <p>{withZero(hours)}</p>
        <span>საათი</span>
      </SpecOfferCountDownTime>
      <SpecOfferCountDownTime>
        <p>{withZero(minutes)}</p>
        <span>წუთი</span>
      </SpecOfferCountDownTime>
      <SpecOfferCountDownTime>
        <p>{withZero(seconds)}</p>
        <span>წამი</span>
      </SpecOfferCountDownTime>
    </SpecOfferCountDown>
  )
}

const RoundedRenderer = ({
  days,
  minutes,
  seconds,
  hours,
  completed,
  className,
}) => {
  if (completed) {
    return 'Finished'
  }

  return (
    <RoundedCountDown className={className || ''}>
      <i>
        {hours} საათი : {minutes} წუთი : {seconds} წამი
      </i>
    </RoundedCountDown>
  )
}

const Renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    return 'Finished'
  }

  return (
    <CountDownWrapper>
      <CountDownTime>
        <p>{hours}</p>
        <span>საათი</span>
      </CountDownTime>
      <span className="dots">:</span>
      <CountDownTime>
        <p>{minutes}</p>
        <span>წუთი</span>
      </CountDownTime>
      <span className="dots">:</span>
      <CountDownTime>
        <p>{seconds}</p>
        <span>წამი</span>
      </CountDownTime>
    </CountDownWrapper>
  )
}

export const CountDown = props => {
  const className = props.className

  const renders = {
    landing: SpecOfferRenderer,
    main: Renderer,
    rounded: res => <RoundedRenderer {...res} className={className || ''} />,
  }

  if (!props.date) {
    return null
  }

  return (
    <ReactCountDown
      date={props.date}
      renderer={
        props.type && renders[props.type] ? renders[props.type] : renders.main
      }
    />
  )
}

export default CountDown
