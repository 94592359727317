import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const FooterContainer = styled.div`
  width: 100%;
  padding: 50px 20px;
  padding-bottom: 32px;
  @media screen and (min-width: 1100px) {
    padding: 50px 0px;
    padding-bottom: 32px;
  }
`

export const FooterTop = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  grid-row-gap: 30px;

  @media screen and (min-width: 1100px) {
    grid-template-columns: 4fr 9fr;
  }
`

export const FooterBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid white;
  padding-top: 20px;
  margin-top: 15px;

  @media screen and (min-width: 1100px) {
    margin-top: 0px;
    flex-direction: row;
    justify-content: space-between;

    p {
      margin-top: 0px;
    }
  }
`

export const CopyrightText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  color: white;
  margin-top: 15px;
`

export const FooterPayment = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-column-gap: 10px;
  img {
    max-height: 25px;
    filter: grayscale(1);
  }
`

export const FooterLogo = styled(Link)`
  img {
    max-width: 180px;
  }
`

export const FooterPhone = styled.a`
  text-decoration: none;
  margin-top: 25px;
  margin-bottom: 10px;
  
  p {
    font-size: 14px;
    color: white;
    font-family: ${props => props.theme.font.family.bold};
    margin-bottom: 10px;
  }

  h4 {
    color: ${props => props.theme.color.white};
    font-size: 31px;
    font-family: Roboto-Bold;
    margin: 0px;
  }
  @media screen and (min-width: 1100px) {
    margin-bottom: 10px;
  }

`

export const FooterContact = styled.div`
  color: white;
  strong {
    display: block;
    font-family: ${props => props.theme.font.family.bold};
    font-size: ${props => props.theme.font.size.sm};
    margin-bottom: 5px;
  }

  p {
    font-size: 14px;
    font-family: ${props => props.theme.font.family.light};
    margin-bottom: 10px;
  }

  address {
    font-size: 16px;
    font-style: normal;
  }

  @media screen and (min-width: 1100px) {

  }
`

export const FooterSocial = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-column-gap: 15px;

  @media screen and (min-width: 1100px) {
  }
`

export const FooterGeneralInfo = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1100px) {
    
  }
`

export const FooterMenu = styled.div`
  width: 100%;
  margin-bottom: 24px;

  h4 {
    font-size: 16px;
    font-family: ${props => props.theme.font.family.bold};
    padding-left: 15px;
    color: white;
    position: relative;
  }

  ul {
    list-style: none;
    li {
      padding: 5px 0px;
      a {
        font-size: 12px;
        color: white;
        text-decoration: none;
      }
    }
  }
  @media screen and (min-width: 1100px) {
    margin-bottom: 0px;
    h4 {
      font-size: 18px;
      margin-bottom: 16px;
      padding-left: 0px;
      &::before {
        left: -20px;
      }
    }

    ul {
      li {
        a {
          font-size: 14px;
        }
      }
    }
  }

`

export const FooterList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  @media screen and (min-width: 1100px) {
    display: flex;
    flex-direction: row;
  }
`
