import React from 'react'
import PropTypes from 'prop-types'
import { Svg } from '../Icons.style'

const Delivery = ({ color, width, height }) => {
  return (
    <Svg color={color} xmlns='http://www.w3.org/2000/svg' width={width || '16'} height={height || '12.797'} viewBox='0 0 16 12.797'>
      <path d='M78.9,106v1.6h2.4l3.2,3.2v5.6H82.9a2.4,2.4,0,0,1-4.8,0H74.9a2.4,2.4,0,0,1-4.8,0H68.5V106Zm4,6.4v-.934L80.636,109.2H79.7v3.2Zm-2.4,4.8a.8.8,0,1,0-.8-.8A.8.8,0,0,0,80.5,117.194Zm-8,0a.8.8,0,1,0-.8-.8A.8.8,0,0,0,72.5,117.194Z' transform='translate(-68.5 -106)' fill={color || '#5a6679'} />
    </Svg>
  )
}

Delivery.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

export default Delivery
