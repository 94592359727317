export const tokenKey = 'citrustoken'
export const signatureKey = 'citrussignature'

export function canCredoCashback(productPrice) {
  return false
  return true
}

export function calculateCredoCashback(productPrice) {
  return Math.round(Math.max(productPrice * 0.7, productPrice - 250))
}

export function calculateCredoCashbackAmount(productPrice) {
  return Math.round(Math.min(productPrice * 0.3, 250))
}

/** Citrus cashbacks */
export function canCitrusCashback(productPrice, payMethod) {
  return false
  return productPrice > 0 && payMethod === 'card'
}

export function calculateCitrusCashback(productPrice) {
  return Math.round(Math.max(productPrice * 0.85, productPrice - 100))
}

export function calculateCitrusCashbackAmount(productPrice) {
  return Math.round(Math.min(productPrice * 0.15, 100))
}
