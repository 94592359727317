import styled from 'styled-components'

export const AddReviewContainer = styled.div`
  border-radius: 6px;
  background-color: white;
  
  display: grid;
  width: 100%;
  box-sizing: border-box;
  @media screen and (min-width: 1100px) {
    &.landing-review {
      position: absolute;
      left: 50%;
      top: 0%;
      transform: translate(-50%, -50%);
      padding: 39px 58px 42px 58px;
      border: 1px solid #DFE3E9;
      grid-template-columns: 5fr 4fr;
    }

  }

`

export const AddreviewContent = styled.div`
  width:100%;

  @media screen and (min-width: 1100px) {
  }
`

export const ChooseReview = styled.div`
  width: 100%;
  padding-bottom: 27px;
  border-bottom: 1px solid #DFE3E9;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 14px;
  align-items: center;
  p {
    font-size: 16px;
    color: #1A2738;
    font-family: ${props => props.theme.font.family.medium};
  }

  .stars {
    display: flex;
    align-items:  center;
    button {
      height: 16px;
      &:not(:first-child) {
        margin-left: 7.5px;
      }
      &.active {
        svg {
          path {
            fill: ${props => props.theme.color.yellow};
            stroke: transparent;
          }
        }
      }
    }
  }
  @media screen and (min-width: 1100px) {
    
  }


`

export const AddReviewForm = styled.form`
  margin-top: 23px;
  display: grid;
  grid-row-gap: 20px;
  
  @media screen and (min-width: 1100px) {
    grid-template-columns: 4.5fr 4.5fr;
    grid-column-gap: 21px;
    grid-template-areas: "name phone"
                       "comment comment"
                       "add-photo submit";
    

    .orange-button {
      grid-area: submit;
      max-width: 193px;
      justify-self: end;
    }
  }


`

export const LabelWithInput = styled.label`
  span {
    font-size: 16px;
    font-family; ${props => props.theme.font.family.medium};
    color: #1A2738;
    display: block;
  }

  input,textarea {
    width: 100%;
    border: 1px solid #DFE3E9;
    border-radius: 24px;
    padding: 15px 24px;
    margin-top: 16px;
    font-size: 16px;
    color: #5A6679;
    box-sizing: border-box;
  }

  textarea {
    width: 100%;
    height: 149px;
    outline: none;
    resize: none;
  }

  
  @media screen and (min-width: 1100px) {
    &.review-name {
      grid-area: name;
    }
  
    &.review-phone {
      grid-area: phone;
    }
  
    &.review-comment {
      grid-area: comment;
    }
  }

`

export const AddPhotoLabel = styled.label`
  input {
    display: none;
  }

  span {
    width: 100%;
    background: #DFE3E9;
    padding: 16px 27px;
    border-radius: 24px;
    font-size: 16px;
    color: #1A2738;
    font-family: ${props => props.theme.font.family.medium};
    svg {
      margin-right: 16px;
    }
  }
  
  @media screen and (min-width: 1100px) {
    grid-area: add-photo;
    button {
      max-width: 260px;
    }
  }

`
