import api from './api'

class ProductService {
  static params = {}

  static applyFilters(f) {
    ProductService.params = f
  }

  static getBySlug = slug => {
    return api.call('get', `/product/${slug}`).then(api.getData)
  }

  static getByBlock = block_id => {
    return api.call('get', `/product/block/${block_id}`).then(api.getData)
  }

  static getByLandingSignature = slug => {
    return api.call('get', `/product/landing/${slug}`).then(api.getData)
  }

  static getPromotions = slug => {
    return api
      .call('get', `/product/promotions`, {
        params: { ...ProductService.params, slug: slug },
      })
      .then(api.getData)
  }

  static getSaleProducts = () => {
    return api
      .call('get', `/product/sale`, {
        params: { ...ProductService.params },
      })
      .then(api.getData)
  }

  static getByCategorySlug = (categorySlug, data) => {
    return api
      .call('get', `/category/${categorySlug}/products`, {
        params: { ...ProductService.params, ...data },
      })
      .then(api.getData)
  }

  static getByBrand = (brandSlug, data) => {
    return api
      .call('get', `/brand/${brandSlug}/products`, {
        params: { ...ProductService.params },
      })
      .then(api.getData)
  }

  static getAllProducts = () => {
    console.log(ProductService.params)
    return api
      .call('get', `/product/all`, {
        params: { ...ProductService.params },
      })
      .then(api.getData)
  }

  static search = (keyword, data) => {
    return api
      .call('get', `/pages/search/${keyword}`, {
        params: { ...ProductService.params },
      })
      .then(api.getData)
  }

  static getStock = (sku, attributes) => {
    return api
      .call('get', `/dressup/stock`, {
        params: {
          sku,
          attributes,
        },
      })
      .then(api.getData)
  }
}

export default ProductService
