import React from 'react'


const Plus2 = ({ color, width, height }) => {
  return (

    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 16C3.6 16 0 12.4 0 8C0 3.6 3.6 0 8 0C12.4 0 16 3.6 16 8C16 12.4 12.4 16 8 16ZM12 7.2H8.8V4H7.2V7.2H4V8.8H7.2V12H8.8V8.8H12V7.2Z" fill="#D9D9D9"/>
    </svg>

  )
}


export default Plus2


