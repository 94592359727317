import useConfig from 'store/ConfigProvider'

const Translate = key => {
  const { translate } = useConfig()

  if (translate && translate[key]) {
    return translate[key]
  }

  return ''
}

export default Translate
