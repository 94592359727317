import React from 'react'
import * as S from './ErrorMessage.style'

const ErrorMessage = props => {
  const { show, message } = props
  const msg = Array.isArray(message) ? message.join(', ') : message

  if (!show) return null

  return <S.ErrorBlock>{msg}</S.ErrorBlock>
}

export default ErrorMessage
