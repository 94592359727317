import api from './api'

class ConfigService {
  static getConfig = () => {
    return api.call('get', `/configs`).then(api.getData)
  }
  static getCategories = () => {
    return api.call('get', `/configs/categories`).then(api.getData)
  }
  static getTopCategories = () => {
    return api.call('get', `/configs/categories/top`).then(api.getData)
  }
}

export default ConfigService
