import api from './api'

class SearchService {
  static search = q => {
    return api.call('get', `/search`, { params: { q: q } }).then(api.getData)
  }

  static searchProductChosen = (search_id, product_id) => {
    return api
      .call('post', `/search-chosen`, {
        search_id,
        product_id,
      })
      .then(api.getData)
  }
}

export default SearchService
